import { RecordEventCreatePrams } from './../types/Stats';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';

const endpoint = ENDPOINTS.STATS;

const createStatParam = (params: RecordEventCreatePrams): Promise<void> =>
  apiClient.post<void>(endpoint, params).then((res) => res.data);

export default { createStatParam };
