import React, { useState } from 'react';
import { Container, Typography, Button, alpha, CircularProgress } from '@mui/material';
import { getMenuItems } from 'api/menuApi';

import colors from 'theme/colors';
import Error404 from 'components/icons/Error404';

const NoInternetPlaceholder: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleReloadPage = async () => {
    try {
      setIsLoading(true);
      await getMenuItems();

      window.location.reload();
    } catch (e) {
      return;
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Container
        maxWidth={'md'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Error404
          sx={{
            width: 600,
            height: 330,
            mb: 7,
          }}
        />
        <Typography
          variant='h1'
          textAlign='center'
          fontSize={36}
          fontWeight={420}
          fontFamily='BlueSky,acumin-pro,sans-serif,Montserrat'
        >
          No Network
        </Typography>
        <Typography
          variant='body1'
          textAlign='center'
          fontSize={27}
          color={alpha('#eee', 0.6)}
          fontFamily='BlueSky,acumin-pro,sans-serif,Montserrat'
        >
          We have lost connection to the network.
        </Typography>
        <Button
          variant='outlined'
          sx={{
            mt: 12,
            border: 'none',
            fontSize: 36,

            color: colors.white,
            background:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.10) 100%);',
            width: 600,
            height: 96,
            '&:hover': {
              border: 'none',
            },
          }}
          onClick={handleReloadPage}
        >
          {isLoading ? <CircularProgress /> : 'Reload'}
        </Button>
      </Container>
    </Container>
  );
};

export default NoInternetPlaceholder;
