import Cookies from 'js-cookie';

const COOKIES = {
  ACCESS_TOKEN: 'screen_hits_access_token',
  ACCOUNT_TOKEN: 'screen_hits_account_token',
};

const setTokenToCookies = (token: string) => {
  return Cookies.set(COOKIES.ACCESS_TOKEN, token, { expires: 90 });
};

const getTokenFromCookies = (): string | undefined => Cookies.get(COOKIES.ACCESS_TOKEN);

const removeTokenFromCookies = (): void => Cookies.remove(COOKIES.ACCESS_TOKEN);

const setAccountTokenToCookies = (token: string) => {
  return Cookies.set(COOKIES.ACCESS_TOKEN, token, { expires: 90 });
};

const getAccountTokenFromCookies = (): string | undefined => Cookies.get(COOKIES.ACCOUNT_TOKEN);

const removeAccessTokenFromCookies = (): void => Cookies.remove(COOKIES.ACCESS_TOKEN);

const removeAccountTokenFromCookies = (): void => Cookies.remove(COOKIES.ACCOUNT_TOKEN);

export {
  setTokenToCookies,
  getTokenFromCookies,
  removeTokenFromCookies,
  setAccountTokenToCookies,
  getAccountTokenFromCookies,
  removeAccountTokenFromCookies,
  removeAccessTokenFromCookies,
};
