const EN_RESOURCE = {
  translation: {
    loading: 'Loading',
    search: {
      cancel: 'Cancel',
      noResults: 'No search results found, but the ones below are definitely worth checking out.',
    },
    menu: {
      switchProfiles: 'Switch Profiles',
      settings: 'Settings',
      close: 'Close',
      logOut: 'Log Out',
    },
    button: {
      apply: 'Apply',
      continue: 'Continue',
      cancel: 'Cancel',
      confirm: 'Confirm',
      play: 'Play',
      addToWatchList: 'Add to Watchlist',
      removeFromWatchList: 'Remove from Watchlist',
      moreInfo: 'More info',
      resume: 'Resume',
      close: 'Close',
      discardChanges: 'Discard changes',
    },
    login: {
      header: 'Account Login',
      title: 'Welcome to',
      subTitle: 'Get the most out of your streaming subscriptions with Disney, Amazon and more.',
      text: 'Everything you want to watch in one place.',
      button: 'Log In',
      termsOfUse: 'Terms of Use',
      placeholder: {
        username: 'Username',
        password: 'Password',
      },
      errors: {
        email: {
          noValid: 'Email must be a valid email address',
          required: 'Email is a required field',
        },
        password: {
          required: 'Password is a required field',
        },
        wrongPass: {
          first: 'Oops, that wasn’t the right password. Please try again or click on the ',
          second: '“Forgot Password” ',
          third: 'button below.',
        },
      },
    },
    forgotPassword: {
      message:
        'Don’t worry. Resetting your password is easy. Just enter your email address below and click "SEND"',
      button: 'SEND',
      placeholder: {
        username: 'Username',
      },
      errors: {
        email: {
          required: 'Email is a required field',
        },
        emailNotRegister: 'Email Address not registered!',
        emailSent: 'Reset password email sent!',
      },
    },
    profilesPage: {
      title: 'Who’s Watching?',
      subTitle: 'Everyone in your car can have a personalized TV experience. Add a new profile.',
      newProfile: 'New Profile',
    },
    newProfile: {
      isKid: 'Kids?',
      loading: 'Loading',
      profileCreated: 'Profile succesfully created!',
      profileSelection: 'Profile Selection',
    },
    settings: {
      accountDetails: {
        primary: 'Account details',
        secondary: 'Edit name of account holder & email.',
      },
      manageProfiles: {
        primary: 'Manage Profiles',
        secondary: 'Edit details',
      },
      parentControl: {
        primary: 'Parental Control',
        secondary: 'Create PIN / Change PIN',
        instruction: '(Please enter 4 digits while the keyboard is open)',
        recoverPin: 'Recover PIN',
        pinSentTo: 'PIN sent to',
      },
      membershipStatus: {
        primary: 'Membership Status',
        secondary: 'Active',
      },
      deactivateAccount: {
        primary: 'Deactivate account',
        secondary: 'Close your account and unlink your devices.',
        enterPass: 'Enter your Password',
      },
      changeLocation: {
        primary: 'Location',
        secondary: 'Change the location',
      },
      activeLanguage: 'Active Language',
      defaultLanguage: 'System Language',
      privacyPolicy: {
        text: 'Privacy Policy',
      },
      settings: 'Settings',
      lang: {
        en: 'English',
        it: 'Italian',
        de: 'German',
        esar: 'Spanish(Argentina)',
        fr: 'French',
        ja: 'Japanese',
        ro: 'Romanian',
        da: 'Danish',
        nl: 'Dutch',
        ca: 'Catalan',
        lb: 'Louxembourgish',
        no: 'Norwegian',
        ptbr: 'Portuguese(BR)',
        zhcn: 'Chinese',
        ms: 'Malay',
        ta: 'Tamil',
        bs: 'Bosnian',
        bg: 'Bulgarian',
        hr: 'Croatian',
        tr: 'Turkish',
        cs: 'Czech',
        et: 'Estonian',
        fi: 'Finnish',
        el: 'Greek',
        hu: 'Hungarian',
        ga: 'Irish',
        is: 'Icelandic',
        lv: 'Latvian',
        lt: 'Lithuanian',
        mt: 'Maltese',
        pl: 'Polish',
        pt: 'Portuguese(PT)',
        ru: 'Russian',
        sr: 'Serbian',
        sk: 'Slovak',
        sl: 'Slovenian',
        sv: 'Swedish',
        mi: 'Māori',
        ar: 'Arabic',
        af: 'Afrikaans',
        zhtw: 'Chinese(Traditional)',
        es: 'Spanish(Spain)',
        ko: 'Korean',
      },
      language: {
        primary: 'Language',
      },
    },
    show: {
      more: ' Show more',
      less: ' Show less',
    },
    showPage: {
      seasons: 'Seasons',
      cast: 'Cast',
      producers: 'Producers',
      genre: 'Genre',
      directedBy: 'Directed by',
      season: 'Season',
    },
    accountDetails: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      changePassword: 'Change Password',
      forgotPassword: 'Forgot Password',
    },
    childPinPopup: {
      title: 'Child Protection',
      subTitle: 'Create (update) PIN for Child Protection',
      pinUpdated: 'PIN updated successfully',
      pinIsIncorrect: 'PIN is incorrect',
    },
    deactivateAccount: {
      title: 'Are you sure you want to deactivate your account?',
      description:
        'Once you do, you will be logged out of all services and will no longer be able to view content in your car',
      deactivateButton: 'Deactivate account',
    },
    servicesPage: {
      add: 'Add to services',
      remove: 'Remove from services',
      overflow: {
        title: 'Selected Packs',
        subtitle: 'Select Your Services',
        description: 'Add each services you use to your guide',
      },
    },
    manageProfile: {
      text: 'Manage Profiles',
    },
    watchlist: {
      title: 'Here are all the movies & shows you’ve added to your Watchlist',
      noTitles: 'You currently have no titles in your Watchlist',
      addText:
        'To add, please click on + Add to Watchlist on any show you would like to come back to and watch at a later date.',
      subtext: {
        start: 'To add, please click on ',
        toWatchList: 'Add to Watchlist',
        end: 'for any show you would like to come back to and watch at a later date.',
      },
    },
    streamingService: {
      title: 'Choose Streaming Services',
      subTitle: 'Add your selected services to appear in your TV Guide',
      streamingServices: 'Streaming Services',
    },
    profileUpdate: {
      backToSettings: 'Back to Settings',
      updatedSuccess: 'User was updated successfully',
      apply: 'Apply',
      wrongPassword: 'Wrong Password',
    },
    changePasswordPage: {
      backToSettings: 'Back to Settings',
      currentPass: 'Current Password',
      newPass: 'New Password',
      confirmPass: 'Confirm Password',
      change: 'Change',
      changeUserInfo: 'Change User Info',
      passwordDoNotMatch: 'Passwords do not match or are invalid',
      passwordUpdated: 'Password updated successfully',
    },
    noServices: {
      title: 'You currently have no services in your TV Guide',
      text: 'To get the most out of ScreenHits TV, you need to add services. Please click here to add the streamers you already subscribe to at no extra cost.',
      button: 'Add Services',
    },
    deleteProfile: {
      cantDeleteProfile: 'You cannot delete your current profile',
      areYouSure: 'Are you sure you want to delete this profile?',
      delete: 'Delete',
    },
    notFoundPage: {
      title: 'Something went wrong',
      text: "Don't worry though, everything is ok. We will come back with this page.",
      home: 'Home',
    },
  },
};

export default EN_RESOURCE;
