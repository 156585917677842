const SR_RESOURCE = {
  translation: {
    loading: 'Учитавање',
    search: {
      cancel: 'Поништити',
      noResults: 'Нису пронађени резултати претраге, али оне испод свакако вреди погледати.',
    },
    menu: {
      switchProfiles: 'Промените профил',
      settings: 'Подешавања',
      close: 'Затвори',
      logOut: 'Одјављивање',
    },
    button: {
      apply: 'Примени',
      continue: 'Настави',
      cancel: 'Поништи',
      confirm: 'Потврди',
      play: 'Емитуј',
      addToWatchList: 'Додај у листу за праћење',
      removeFromWatchList: 'Уклони са листе за праћење',
      moreInfo: 'Више информација',
      resume: 'Настави са радом',
      close: 'Затвори',
    },
    login: {
      header: 'Пријава на налог',
      title: 'Добродошли у',
      subTitle:
        'Извуците максимум из својих претплата за стриминг уз Нетфликс, Дизни, Амазон и још много тога.',
      text: 'Све што желите да гледате на једном месту.',
      button: 'Пријавите се',
      termsOfUse: 'Услови коришћења',
      placeholder: {
        username: 'Корисничко име',
        password: 'Лозинка',
      },
      errors: {
        email: {
          noValid: 'Е-пошта мора да буде важећа адреса е-поште',
          required: 'Е-пошта је обавезно поље',
        },
        password: {
          required: 'Лозинка је обавезно поље',
        },
        wrongPass: {
          first: 'Упс, то није била права лозинка. Покушајте поново или кликните на ',
          second: '“Заборавио сам лозинку” ',
          third: 'дугме испод.',
        },
      },
    },
    forgotPassword: {
      message:
        'Не брините. Ресетовање Ваше лозинке је једноставно. Само унесите своју е-поште испод и кликните на "ПОШАЉИ"',
      button: 'ПОШАЉИ',
      placeholder: {
        username: 'Корисничко име',
      },
      errors: {
        email: {
          required: 'Е-пошта је обавезно поље',
        },
        emailNotRegister: 'Адреса е-поште није регистрована!',
        emailSent: 'Порука е-поште за ресетовање лозинке је послата!',
      },
    },
    profilesPage: {
      title: 'Ко гледа?',
      subTitle: 'Сви у вашем аутомобилу могу имати персонализовано ТВ искуство. Додајте нови профил.',
      newProfile: 'Нови профил',
    },
    newProfile: {
      isKid: 'Деца?',
      loading: 'Учитавање',
      profileCreated: 'Профил је успешно направљен!',
      profileSelection: 'Избор профила',
    },
    settings: {
      accountDetails: {
        primary: 'Детаљи о налогу',
        secondary: 'Измените име власника налога и е-пошту.',
      },
      manageProfiles: {
        primary: 'Управљајте профилима',
        secondary: 'Уредите детаље',
      },
      parentControl: {
        primary: 'Родитељска контрола',
        secondary: 'Креирајте ПИН/Промените ПИН',
        instruction: '(Унесите 4 цифре док је тастатура отворена)',
        recoverPin: 'Поврати ПИН',
        pinSentTo: 'ПИН послат на',
      },
      membershipStatus: {
        primary: 'Статус чланства',
        secondary: 'Активан',
      },
      deactivateAccount: {
        primary: 'Деактивирати налог',
        secondary: 'Затворите налог и прекините везу са уређајима.',
        enterPass: 'Унесите лозинку',
      },
      activeLanguage: 'Ацтиван јеѕик',
      defaultLanguage: 'Системски језик',
      privacyPolicy: {
        text: 'Правила о приватности',
      },
      settings: 'Подешавања',
      lang: {
        en: 'Eнглески',
        it: 'Италијански',
        de: 'Немачки',
        esar: 'Шпански(аргентински)',
        fr: 'Француски',
        ja: 'Jапански',
        ro: 'Pумунски',
        da: 'Дански',
        nl: 'Холандски',
        ca: 'Каталонски',
        lb: 'Луксембуршки',
        no: 'Норвешки',
        ptbr: 'Португалски(Бразил)',
        zhcn: 'Кинески',
        ms: 'Mалајски',
        ta: 'Тамилски',
        bs: 'Босански',
        bg: 'Бугарски',
        hr: 'Хрватски',
        tr: 'Турски',
        cs: 'Чешки',
        et: 'Естонски',
        fi: 'Фински',
        el: 'Грчки',
        hu: 'Мађарски',
        ga: 'Ирски',
        is: 'Исландски',
        lv: 'Латвијски',
        lt: 'Литвански',
        mt: 'Малтешки',
        pl: 'Пољски',
        pt: 'Португалски(Португал)',
        ru: 'Руски',
        sr: 'Српски',
        sk: 'Cловачки',
        sl: 'Cловеначки',
        sv: 'Шведски',
        mi: 'Маорски',
        ar: 'Арапски',
        af: 'Африканс',
        zhtw: 'Кинески(традиционалан)',
        es: 'Шпански(Шпанија)',
        ko: 'Корејски',
      },

      language: {
        primary: 'Језик',
      },
    },
    show: {
      more: ' Прикажи више',
      less: ' Прикажи мање',
    },
    showPage: {
      seasons: 'Сезоне',
      cast: 'Глумци',
      producers: 'Продуценти',
      genre: 'Жанр',
      directedBy: 'Режирао',
      season: 'Сезона',
    },
    accountDetails: {
      firstName: 'Име',
      lastName: 'Презиме',
      email: 'Е-пошта',
      password: 'Лозинка',
      changePassword: 'Промени лозинку',
      forgotPassword: 'Заборавили сте лозинку',
    },
    childPinPopup: {
      title: 'Заштита деце',
      subTitle: 'Креирајте (ажурирајте) ПИН за заштиту деце',
      pinUpdated: 'ПИН је успешно ажуриран',
      pinIsIncorrect: 'ПИН је нетачан',
    },
    deactivateAccount: {
      title: 'Да ли сте сигурни да желите да деактивирате свој налог?',
      description:
        'Када то учините, бићете одјављени са свих услуга и више нећете моћи да гледате садржај у свом аутомобилу',
      deactivateButton: 'Деактивирај налог',
    },
    servicesPage: {
      add: 'Додај у услуге',
      remove: 'Уклони из услуга',
      overflow: {
        title: 'Одабрани пакети',
        subtitle: 'Изаберите своје услуге',
        description: 'Додајте све услуге које користите у свој водич',
      },
    },
    manageProfile: {
      text: 'Управљајте профилима',
    },
    watchlist: {
      title: 'Ово су филмови и емисије које сте додали на своју листу за гледање',
      noTitles: 'Тренутно немате ниједан наслов на вашој листи за гледање',
      addText:
        'Да бисте додали, кликните на + Додај на листу за гледање на било којој емисији на коју желите да се вратите и погледате касније.',
      subtext: {
        start: 'Да бисте додали, кликните на ',
        toWatchList: 'Додај у листу за праћење',
        end: 'за било коју емисију којој бисте желели да се вратите и погледате касније.',
      },
    },
    streamingService: {
      title: 'Изаберите услуге стримовања',
      subTitle: 'Додајте своје изабране услуге да би се појавиле у Вашем ТВ водичу',
      streamingServices: 'Стриминг сервиси',
    },
    profileUpdate: {
      backToSettings: 'Назад на подешавања',
      updatedSuccess: 'Корисник је успешно ажуриран',
      apply: 'Примени',
      wrongPassword: 'Погрешна лозинка',
    },
    changePasswordPage: {
      backToSettings: 'Назад на подешавања',
      currentPass: 'Тренутна лозинка',
      newPass: 'Нова лозинка',
      confirmPass: 'Потврди лозинку',
      change: 'Промени',
      changeUserInfo: 'Промени информације о кориснику',
      passwordDoNotMatch: 'Лозинке се не подударају или су неважеће',
      passwordUpdated: 'Лозинка је успешно ажурирана',
    },
    noServices: {
      title: 'Тренутно немате ниједну услугу у свом ТВ водичу',
      text: 'Да бисте максимално искористили ScreenHits TV, морате да додате услуге. Кликните овде да додате стримере на које сте се већ претплатили без додатних трошкова. Уколико се тренутно нисте претплатили ни на једну услугу стриминга, додајте наш БЕСПЛАТНИ пакет и почните да откривате одличан садржај са BBC, ITV, My5, Channel 4,UKTV и STV. ',
      button: 'Додај услуге',
    },
    deleteProfile: {
      cantDeleteProfile: 'Не можете да избришете свој тренутни профил',
      areYouSure: 'Да ли сте сигурни да желите да избришете овај профил?',
      delete: 'Избриши',
    },
    notFoundPage: {
      title: 'Нешто није у реду',
      text: 'Ипак, не брините, све је у реду. Вратићемо се са овом страницом.',
      home: 'Почетна страница',
    },
  },
};

export default SR_RESOURCE;
