import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import { AppStoreProvider } from 'store';
import Theme from './Theme';
import SWRConfigWrapper from 'components/wrappers/SWRConfigWrapper';
import { Suspense } from 'react';
import BackdropLoader from 'components/Loaders/BackdropLoader';
import UserDataWrapper from './UserDataWrapper';
import './styles.css';
// import ErrorBoundaryFn from './ErrorBoundary';
import NoInternetWrapper from './NoInternetWrapper';

const App: React.FC = (): JSX.Element => {
  return (
    <SWRConfigWrapper>
      <AppStoreProvider>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <Theme>
              <UserDataWrapper>
                <CssBaseline />
                {/* <ParentControl /> */}
                <Suspense fallback={<BackdropLoader />}>
                  <NoInternetWrapper>
                    {/* <ErrorBoundaryFn> */}
                    <AppRoutes />
                    {/* </ErrorBoundaryFn> */}
                  </NoInternetWrapper>
                </Suspense>
              </UserDataWrapper>
            </Theme>
          </SnackbarProvider>
        </BrowserRouter>
      </AppStoreProvider>
    </SWRConfigWrapper>
  );
};

export default App;
