import { Box } from '@mui/material';
import statsApi from 'api/statsApi';
import VideoPlayer from 'components/VideoJsPlayer';
import useAxiosRequest from 'hooks/useApiRequest';
import { FC, useRef, useEffect, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { RecordEventCreatePrams } from 'types/Stats';

import videojs from 'video.js';

const SEND_STATS_TIMEOUT = 5000;

const Video: FC = (): JSX.Element => {
  const playerRef = useRef<videojs.Player | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const { requestFunc } = useAxiosRequest();

  const { id: mediaId } = useParams();

  const viewProgress = searchParams.has('viewProgress') && searchParams.get('viewProgress');
  const link = searchParams.has('link') ? searchParams.get('link') : '';

  const sourceId = searchParams.has('sourceId') && Number(searchParams.get('sourceId'));
  const episodeId = searchParams.has('episodeId') ? Number(searchParams.get('episodeId')) : 0;
  // const channelId = searchParams.has('channelId') && Number(searchParams.get('channelId'));
  // const backPath = searchParams.has('backPath') && searchParams.get('backPath');
  const withoutStats = searchParams.has('withoutStats') && searchParams.get('withoutStats');
  const mediaTitle = searchParams.has('title') && searchParams.get('title');

  const videoJsOptions = useMemo(() => {
    return {
      sources: [
        {
          src: link || '',
          type: 'application/x-mpegURL',
        },
      ],
    };
  }, [link]);

  const handleUpdateViewProgress = useCallback(
    (time: string) => {
      searchParams.set('viewProgress', time);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  const handleSentStat = useCallback(
    async (params: RecordEventCreatePrams) => {
      await requestFunc(statsApi.createStatParam(params));
    },
    [requestFunc],
  );

  const handleDetectEvent = useCallback(
    (event: string, timeCode: number) => {
      if (!withoutStats) {
        // do not send event if player was opened from live TV
        handleSentStat([
          {
            Event: event,
            MediaId: Number(mediaId),
            SourceId: sourceId || null,
            TimeCode: timeCode,
            EpisodeId: episodeId || 0,
          },
        ]);
      }
    },
    [handleSentStat, sourceId, episodeId, mediaId, withoutStats],
  );

  // set player properties
  const handlePlayerReady = useCallback(
    (player: videojs.Player) => {
      playerRef.current = player;

      const volumeLevel = localStorage.getItem('_vl');

      if (volumeLevel) {
        player?.volume(Number(volumeLevel));
      } else {
        player?.volume(0.5);
      }

      const currentTime = player.currentTime();

      if (viewProgress) {
        playerRef.current.currentTime(Number(viewProgress));
        playerRef.current.play();
      }

      player.on('play', () => {
        handleDetectEvent('Play', currentTime);
      });

      player.on('pause', () => {
        handleDetectEvent('Pause', currentTime);
        handleUpdateViewProgress(currentTime.toString());
      });

      player.on('seeking', () => {
        if (currentTime) {
          handleUpdateViewProgress(currentTime.toString());
          handleDetectEvent('Seek', currentTime);
        }
      });

      player.on('seeked', () => {
        if (currentTime) {
          player.play();
          handleUpdateViewProgress(currentTime.toString());
          handleDetectEvent('Seek', currentTime);
        }
      });

      player.on('ended', () => {
        handleDetectEvent('Ended', currentTime);
      });

      player.on('waiting', () => {
        player.addClass('vjs-nio-waiting');
      });

      player.on('playing', () => {
        player.removeClass('vjs-nio-waiting');
      });

      player.on('userinactive', () => {
        // vjs-menu vjs-lock-showing
        // vjs-hidden
        const menus = document.getElementsByClassName('vjs-menu');
        Array.from(menus)?.forEach((m) => {
          m.classList.remove('vjs-lock-showing');
        });
      });
    },
    [handleDetectEvent, handleUpdateViewProgress, viewProgress],
  );

  useEffect(() => {
    const playerSendStatInterval = setInterval(() => {
      const currentTime = playerRef.current?.currentTime();
      if (currentTime && !playerRef.current?.paused()) {
        handleUpdateViewProgress(currentTime.toString());
        handleDetectEvent('Playing', currentTime);
      }
    }, SEND_STATS_TIMEOUT);

    return () => clearInterval(playerSendStatInterval);
  }, [handleUpdateViewProgress, handleDetectEvent]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 1,
        height: '100vh',
      }}
    >
      <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} title={mediaTitle} />
    </Box>
  );
};

export default Video;
