const PL_RESOURCE = {
  translation: {
    loading: 'Ładowanie',
    search: {
      cancel: 'Anuluj',
      noResults:
        'Nie znaleziono wyników wyszukiwania, ale te poniżej są zdecydowanie warte sprawdzenia.',
    },
    menu: {
      switchProfiles: 'Przełącz profile',
      settings: 'Ustawienia',
      close: 'Zamknij',
      logOut: 'Wyloguj się',
    },
    button: {
      apply: 'Zastosuj',
      continue: 'Kontynuuj',
      cancel: 'Anuluj',
      confirm: 'Potwierdź',
      play: 'Odtwórz',
      addToWatchList: 'Dodaj do WatchListy',
      removeFromWatchList: 'Usuń z WatchListy',
      moreInfo: 'Więcej informacji',
      resume: 'Wznów',
      close: 'Zamknij',
    },
    login: {
      header: 'Logowanie',
      title: 'Witamy w',
      subTitle:
        'Wykorzystaj w pełni swoje subskrypcje streamingowe w serwisach Netflix, Disney, Amazon i nie tylko.',
      text: 'Wszystko, czego chcesz oglądać w jednym miejscu.',
      button: 'Zaloguj się',
      termsOfUse: 'Warunki korzystania',
      placeholder: {
        username: 'Nazwa użytkownika',
        password: 'Hasło',
      },
      errors: {
        email: {
          noValid: 'Adres e-mail musi być prawidłowy',
          required: 'Adres e-mail jest polem wymaganym',
        },
        password: {
          required: 'Hasło jest polem wymaganym',
        },
        wrongPass: {
          first: 'Ups, to nie było prawidłowe hasło. Spróbuj ponownie lub kliknij przycisk ',
          second: '“Nie pamiętam hasła” ',
          third: 'znajdujący się poniżej.',
        },
      },
    },
    forgotPassword: {
      message:
        'Nie martw się. Zmiana hasła jest prosta. Wystarczy, że wpiszesz poniżej swój adres e-mail i klikniesz „WYŚLIJ”.',
      button: 'WYŚLIJ',
      placeholder: {
        username: 'Nazwa użytkownika',
      },
      errors: {
        email: {
          required: 'Email jest polem wymaganym!',
        },
        emailNotRegister: 'Ten adres e-mail nie jest zarejestrowany.',
        emailSent: 'Wysłano wiadomość e-mail do zmiany hasła!',
      },
    },
    profilesPage: {
      title: 'Kto ogląda?',
      subTitle:
        'Każdy w twoim samochodzie może mieć spersonalizowane doświadczenie z telewizją. Dodaj nowy profil.',
      newProfile: 'Nowy profil',
    },
    newProfile: {
      isKid: 'Dziecko?',
      loading: 'Ładowanie',
      profileCreated: 'Profil został pomyślnie utworzony!',
      profileSelection: 'Wybór profilu',
    },
    settings: {
      accountDetails: {
        primary: 'Dane dotyczące konta',
        secondary: 'Edycja nazwy posiadacza konta i adresu e-mail.',
      },
      manageProfiles: {
        primary: 'Zarządzaj profilami',
        secondary: 'Edytuj szczegóły',
      },
      parentControl: {
        primary: 'Kontrola rodzicielska',
        secondary: 'Utwórz kod PIN / Zmień kod PIN',
        instruction: '(Wprowadź 4 cyfry na wyświetlonej klawiaturze)',
        recoverPin: 'Odzyskaj kod PIN',
        pinSentTo: 'Kod PIN wysłany do',
      },
      membershipStatus: {
        primary: 'Status członkostwa',
        secondary: 'Aktywny',
      },
      deactivateAccount: {
        primary: 'Dezaktywuj konto',
        secondary: 'Zamknij swoje konto i odłącz swoje urządzenia.',
        enterPass: 'Wpisz swoje hasło',
      },
      activeLanguage: 'Aktywny język',
      defaultLanguage: 'Język systemu',
      privacyPolicy: {
        text: 'Polityka prywatności',
      },
      settings: 'Ustawienia',
      lang: {
        en: 'Angielski',
        it: 'Włoski',
        de: 'Niemiecki',
        esar: 'Hiszpański(Argentyna)',
        fr: 'Francuski',
        ja: 'Japoński',
        ro: 'Rumuński',
        da: 'Duński',
        nl: 'Holenderski',
        ca: 'Kataloński',
        lb: 'Luksemburski',
        no: 'Norweski',
        ptbr: 'Portugalski(Brazylia)',
        zhcn: 'Chiński',
        ms: 'Malajski',
        ta: 'Tamilski',
        bs: 'Bośniacki',
        bg: 'Bułgarski',
        hr: 'Chorwacki',
        tr: 'Turecki',
        cs: 'Czeski',
        et: 'Estoński',
        fi: 'Fiński',
        el: 'Grecki',
        hu: 'Węgierski',
        ga: 'Irlandzki',
        is: 'Islandzki',
        lv: 'Łotewski',
        lt: 'Litewski',
        mt: 'Maltański',
        pl: 'Polski',
        pt: 'Portugalski(Portugalia)',
        ru: 'Rosyjski',
        sr: 'Serbski',
        sk: 'Słowacki',
        sl: 'Słoweński',
        sv: 'Szwedzki',
        mi: 'Maoryski',
        ar: 'Arabski',
        af: 'Afrykanerski',
        zhtw: 'Chiński(Tradycyjny)',
        es: 'Hiszpański(Hiszpania)',
        ko: 'Koreański',
      },
      language: {
        primary: 'Język',
      },
    },
    show: {
      more: ' Pokaż więcej',
      less: ' Pokaż mniej',
    },
    showPage: {
      seasons: 'Sezony',
      cast: 'Obsada',
      producers: 'Producenci',
      genre: 'Gatunek',
      directedBy: 'Reżyseria',
      season: 'Sezon',
    },
    accountDetails: {
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'Adres e-mail',
      password: 'Hasło',
      changePassword: 'Zmień hasło',
      forgotPassword: 'Nie pamiętam hasła',
    },
    childPinPopup: {
      title: 'Kontrola rodzicielska',
      subTitle: 'Utwórz (aktualizuj) kod PIN celem kontroli rodziecielskiej',
      pinUpdated: 'Kod PIN pomyślnie zaktualizowany',
      pinIsIncorrect: 'Kod PIN jest nieprawidłowy',
    },
    deactivateAccount: {
      title: 'Czy na pewno chcesz dezaktywować swoje konto?',
      description:
        'Gdy to zrobisz, zostaniesz wylogowany ze wszystkich usług i nie będziesz już mógł przeglądać treści w samochodzie.',
      deactivateButton: 'Dezaktywuj konto',
    },
    servicesPage: {
      add: 'Dodaj do usług',
      remove: 'Usuń z usług',
      overflow: {
        title: 'Wybrane pakiety',
        subtitle: 'Wybierz swoje usługi',
        description: 'Dodaj każdą usługę, z której korzystasz do swojego przewodnika',
      },
    },
    manageProfile: {
      text: 'Zarządzaj profilami',
    },
    watchlist: {
      title: 'Oto wszystkie filmy i programy, które dodałeś do WatchListy',
      noTitles: 'Nie masz obecnie żadnych tytułów na swojej WatchLiście.',
      addText:
        'Aby dodać program, kliknij na + Dodaj do WatchListy i dodaj każdy program, do którego chcesz wrócić i obejrzeć go w późniejszym czasie.',
      subtext: {
        start: 'Aby dodać program, kliknij na ',
        toWatchList: 'Dodaj do WatchListy',
        end: 'i dodaj każdy program, do którego chcesz wrócić i obejrzeć go w późniejszym czasie.',
      },
    },
    streamingService: {
      title: 'Wybierz usługi streamingowe',
      subTitle: 'Dodaj wybrane usługi, aby pojawiły się w Twoim Przewodniku TV',
      streamingServices: 'Usługi streamingowe',
    },
    profileUpdate: {
      backToSettings: 'Powrót do ustawień',
      updatedSuccess: 'Użytkownik został pomyślnie zaktualizowany',
      apply: 'Zastosuj',
      wrongPassword: 'Błędne hasło',
    },
    changePasswordPage: {
      backToSettings: 'Powrót do ustawień',
      currentPass: 'Aktualne hasło',
      newPass: 'Nowe hasło',
      confirmPass: 'Potwierdź hasło',
      change: 'Zmień',
      changeUserInfo: 'Zmień informacje o użytkowniku',
      passwordDoNotMatch: 'Hasła nie pasują do siebie lub są nieprawidłowe',
      passwordUpdated: 'Hasło zostało pomyślnie zaktualizowane',
    },
    noServices: {
      title: 'Obecnie nie masz żadnych usług w swoim Przewodniku TV',
      text: 'Aby w pełni wykorzystać funkcję ScreenHits TV, musisz dodać usługi. Kliknij tutaj, aby dodać usługi streamingowe, które już subskrybujesz, bez dodatkowych kosztów. A jeśli obecnie nie subskrybujesz żadnych usług streamingowych, dodaj nasz DARMOWY pakiet i zacznij odkrywać wspaniałe treści z BBC, ITV, My5, Channel 4, UKTV i STV.',
      button: 'Dodaj usługi',
    },
    deleteProfile: {
      cantDeleteProfile: 'Nie możesz usunąć swojego obecnego profilu',
      areYouSure: 'Czy na pewno chcesz usunąć ten profil?',
      delete: 'Usuń',
    },
    notFoundPage: {
      title: 'Coś poszło nie tak',
      text: 'Nie martw się jednak, wszystko jest w porządku. Wrócimy z tą stroną.',
      home: 'Strona główna',
    },
  },
};

export default PL_RESOURCE;
