import { setApiLanguageHeader, setAuthorizationHeader } from 'api/apiClient';
import BackdropLoader from 'components/Loaders/BackdropLoader';
import {
  getAccountTokenFromCookies,
  getTokenFromCookies,
  removeTokenFromCookies,
} from 'helpers/cookies';
import {
  getLocationFromLocalStorage,
  setSelectedProfileToLocalStorage,
  getSelectedProfileFromLocalStorage,
  getAccessTokenFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  setAccessTokenToLocalStorage,
  setRefreshTokenToLocalStorage,
} from 'helpers/localStorage';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../store';
import { setIsAuth, SetLocation, setSelectedProfile } from '../store/actions';
import { getBrowserLanguage } from 'helpers/Common';
import useAxiosRequest from 'hooks/useApiRequest';
import { ProfileData } from 'types/Profile';
import jwt_decode from 'jwt-decode';
import { refreshSession, setCurrentProfile } from 'api/authApi';
import { useNavigate } from 'react-router-dom';
import { appLinks } from 'routes/routes';

const NB_NORWEGIAN_KEY = 'nb';
const NN_NORWEGIAN_KEY = 'nn';
const NORWEGIAN_KEY = 'no';

interface Props {
  children: React.ReactNode;
}

const UserDataWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { dispatch } = useAppContext();
  const location = getLocationFromLocalStorage();
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const { requestFunc } = useAxiosRequest();
  const [isLoading, setIsLoading] = useState(true);

  const logoutCurrentUser = () => {
    dispatch(setIsAuth(false));
    window.localStorage.clear();
    removeTokenFromCookies();
  };

  const getCurrentUser = useCallback(async () => {
    const accessToken = getTokenFromCookies();
    const accountToken = getAccountTokenFromCookies();

    const userLanguage = getBrowserLanguage().replace('-', '');

    setApiLanguageHeader(location || userLanguage || 'en');

    if (location) {
      dispatch(SetLocation(location));
      i18n.changeLanguage(location.replace('-', ''));
    } else {
      if (userLanguage === NB_NORWEGIAN_KEY || userLanguage === NN_NORWEGIAN_KEY) {
        dispatch(SetLocation(NORWEGIAN_KEY));
        i18n.changeLanguage(NORWEGIAN_KEY);
      } else {
        i18n.changeLanguage(userLanguage);
        dispatch(SetLocation(userLanguage));
      }
    }

    if (accessToken) {
      setAuthorizationHeader(accessToken);
      dispatch(setIsAuth(true));
      const user = getSelectedProfileFromLocalStorage();
      let selectedProfileObj = null;

      if (user) {
        try {
          selectedProfileObj = JSON.parse(user as unknown as string);
        } catch (error) {
          console.error('Failed to parse user profile from localStorage:', error);
        }
      }

      if (selectedProfileObj) {
        return selectedProfileObj;
      } else {
        const decoded = await jwt_decode<{ profile: number; exp: number }>(accessToken);

        if (!decoded) {
          navigate(appLinks.home.link, { replace: true });
        }

        const user = await requestFunc(setCurrentProfile(decoded.profile));
        return user;
      }
    }

    if (accountToken && !accessToken) {
      setAuthorizationHeader(accountToken);
    }
    // eslint-disable-next-line
  }, [dispatch, i18n, location]);

  useLayoutEffect(() => {
    async function checkToken() {
      const token = getAccessTokenFromLocalStorage();
      const refresh = getRefreshTokenFromLocalStorage();

      if (token && refresh) {
        try {
          const tokens = await requestFunc(refreshSession({ token, refresh }));
          if (tokens?.token && tokens.refreshToken) {
            setAccessTokenToLocalStorage(tokens.token);
            setRefreshTokenToLocalStorage(tokens.refreshToken);
            return;
          }
        } catch {
          logoutCurrentUser();
        }

        logoutCurrentUser();
      }
    }

    checkToken();
    //  eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    getCurrentUser().then((currentProfile) => {
      setIsLoading(false);

      dispatch(setSelectedProfile(currentProfile as unknown as ProfileData));
      setSelectedProfileToLocalStorage(currentProfile as unknown as ProfileData);
    });
    // eslint-disable-next-line
  }, [getCurrentUser]);

  return isLoading ? <BackdropLoader /> : <div>{children}</div>;
};

export default UserDataWrapper;
