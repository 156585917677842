import { DeactivateUserParams, UserUpdateParams, UpdateUserPasswordParams } from './../types/Auth';
import { apiClient } from './apiClient';
import {
  LoginParams,
  LoginPromise,
  UserData,
  RememberPasswordPromise,
  RememberPasswordResponse,
  ParentProtectionPromise,
  ParentControlResponse,
  QRDetailsPromise,
  QRDetailsResponse,
} from 'types/Auth';
import ENDPOINTS from './endpoints';
import { CurrentUserProfileResponse, CurrentUserPromise } from 'types/Profile';
import axios from 'axios';

const endpoint = ENDPOINTS.AUTH_USER;

const authApiUrl = {
  signIn: `${endpoint}/authenticate`,
  setProfile: `${endpoint}/setprofile`,
  rememberPassword: `${endpoint}/forgotpassword`,
  checkPin: `${endpoint}/checkpin`,
  qrDetails: `${endpoint}/getqrdetails`,
  tryAuth: (guid: string): string => `${endpoint}/tryauthenticate?g=${guid}`,
  deactivateUser: `${endpoint}/deactivate`,
  setPin: `${endpoint}/setpin`,
  recoverPin: `${endpoint}/pinrecover`,
  userUpdate: `${endpoint}/update`,
  updatePassword: `${endpoint}/updatepassword`,
  logout: `${endpoint}/logout`,
  refreshToken: `${endpoint}/refreshtoken`,
};

const signIn = ({ Email, Password }: LoginParams): LoginPromise =>
  apiClient
    .post<UserData>(authApiUrl.signIn, {
      Email,
      Password,
    })
    .then((res) => res.data);

const setCurrentProfile = (id: number): CurrentUserPromise =>
  apiClient
    .post<CurrentUserProfileResponse>(authApiUrl.setProfile, { ProfileId: id })
    .then((res) => res.data);

const rememberPassword = (Email: string): RememberPasswordPromise =>
  apiClient
    .post<RememberPasswordResponse>(authApiUrl.rememberPassword, { Email })
    .then((res) => res.data);

const checkParentControlPassword = (pin: number | string): ParentProtectionPromise =>
  apiClient.post<ParentControlResponse>(authApiUrl.checkPin, { pin }).then((res) => res.data);

const setParentControlPassword = (pin: number): ParentProtectionPromise =>
  apiClient.post<ParentControlResponse>(authApiUrl.setPin, { pin }).then((res) => res.data);

const recoverPin = (): Promise<void> =>
  apiClient.post<void>(authApiUrl.recoverPin).then((res) => res.data);

const getQrDetails = (): QRDetailsPromise =>
  apiClient.get<QRDetailsResponse>(authApiUrl.qrDetails).then((res) => res.data);

const tryAuthenticate = (guid: string): LoginPromise =>
  axios
    .get<UserData>(`${process.env.REACT_APP_API_BASE_URL}` + authApiUrl.tryAuth(guid), {
      params: {
        q: guid,
      },
    })
    .then((res) => res.data);

const deactivateUser = (params: DeactivateUserParams): Promise<void> =>
  apiClient.post<void>(authApiUrl.deactivateUser, params).then((res) => res.data);

const userUpdate = (params: UserUpdateParams): LoginPromise =>
  apiClient.post<UserData>(authApiUrl.userUpdate, params).then((res) => res.data);

const updatePassword = (params: UpdateUserPasswordParams): Promise<{ message: string }> =>
  apiClient.post<{ message: string }>(authApiUrl.updatePassword, params).then((res) => res.data);

const logout = (sessionId: string): Promise<void> =>
  apiClient
    .post<void>(authApiUrl.logout, {
      SessionId: sessionId,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((res) => res.data);

const refreshSession = async ({
  token,
  refresh,
}: {
  token: string;
  refresh: string;
}): Promise<{ token: string; refreshToken: string }> =>
  await apiClient
    .post(authApiUrl.refreshToken, {
      Token: token,
      RefreshToken: refresh,
    })
    .then((res) => res.data);

export {
  authApiUrl,
  signIn,
  setCurrentProfile,
  rememberPassword,
  checkParentControlPassword,
  setParentControlPassword,
  recoverPin,
  getQrDetails,
  tryAuthenticate,
  deactivateUser,
  userUpdate,
  updatePassword,
  logout,
  refreshSession,
};
