import { useState } from 'react';
import { AxiosError } from 'axios';

const useAxiosRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, serIsError] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);

  const requestFunc = async <T,>(apiRequest: T): Promise<T | undefined> => {
    try {
      setIsLoading(true);
      return await apiRequest;
    } catch (e) {
      if (e instanceof AxiosError) {
        serIsError(true);
        setError(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { requestFunc, error, isLoading, isError };
};

export default useAxiosRequest;
