const ENDPOINTS = {
  AUTH_USER: '/user',
  PROFILE: '/profile',
  LINEAR: '/linear',
  MEDIA: '/media',
  GENRE: '/genre',
  CHANNEL: '/channel',
  MISC: '/misc',
  TV_FRIENDS: '/tvfriends',
  STATS: '/stats/recordevent',
  COUNTRY: '/country',
};

export default ENDPOINTS;
