const setIsPasswordEntered = () => {
  sessionStorage.setItem('isPWDEntered', 'true');
};

const getIsPasswordEntered = (): string | null => {
  return sessionStorage.getItem('isPWDEntered') || null;
};

const clearSessionStorage = () => {
  return sessionStorage.clear();
};

export { setIsPasswordEntered, getIsPasswordEntered, clearSessionStorage };
