import { SWRConfig } from 'swr';

interface Props {
  children: React.ReactNode;
}

const SWRConfigWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <SWRConfig
      value={{
        suspense: true,
        revalidateOnFocus: false,
        revalidateOnMount: true,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SWRConfigWrapper;
