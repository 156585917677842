const ZH_TW_RESOURCE = {
  translation: {
    loading: '正在載入',
    search: {
      cancel: '取消',
      noResults: '未找到搜索結果，但以下結果絕對值得查看。',
    },
    menu: {
      switchProfiles: '切換設定檔',
      settings: '設定',
      close: '關閉',
      logOut: '登出',
    },
    button: {
      apply: '套用',
      continue: '繼續',
      cancel: '取消',
      confirm: '確認',
      play: '播放',
      addToWatchList: '新增至關注清單',
      removeFromWatchList: '從關注清單中移除',
      moreInfo: '更多資訊',
      resume: '恢復',
      close: '關閉',
    },
    login: {
      header: '帳戶登入',
      title: '歡迎來到',
      subTitle: '透過網飛、迪士尼、亞馬遜等平台充分利用您的串流訂閱。',
      text: '您想在一個地方觀看的所有內容。',
      button: '登入',
      termsOfUse: '使用條款',
      placeholder: {
        username: '使用者名稱',
        password: '密碼',
      },
      errors: {
        email: {
          noValid: '電子郵件必須是有效的電子郵件地址',
          required: '電子郵件是必填欄位',
        },
        password: {
          required: '密碼是必填欄位',
        },
        wrongPass: {
          first: '糟糕，這不是正確的密碼。請再試一次或按一下 ',
          second: '“以下的「忘記密碼」 ” ',
          third: '按鈕。',
        },
      },
    },
    forgotPassword: {
      message: '別擔心。重設密碼很容易。只需在下面輸入您的電子郵件地址，然後按一下「傳送」',
      button: '傳送',
      placeholder: {
        username: '使用者名稱',
      },
      errors: {
        email: {
          required: '電子郵件是必填欄位',
        },
        emailNotRegister: '電子郵件地址未註冊！',
        emailSent: '重設密碼電子郵件已傳送！',
      },
    },
    profilesPage: {
      title: '誰在觀看？',
      subTitle: '您車內的每個人都可以擁有個性化的電視體驗。新增一個新設定檔。',
      newProfile: '新的個人資料',
    },
    newProfile: {
      isKid: '孩子？',
      loading: '正在載入',
      profileCreated: '已成功建立個人資料！',
      profileSelection: '個人資料選擇',
    },
    settings: {
      accountDetails: {
        primary: '帳戶詳細資料',
        secondary: '編輯帳戶持有人的姓名和電子郵件。',
      },
      manageProfiles: {
        primary: '管理個人資料',
        secondary: '編輯詳細資料',
      },
      parentControl: {
        primary: '家長監護',
        secondary: '建立 PIN／變更 PIN',
        instruction: '（請在鍵盤開啟時輸入 4 位數）',
        recoverPin: '復原 PIN',
        pinSentTo: '傳送 PIN 至',
      },
      membershipStatus: {
        primary: '會員資格狀態',
        secondary: '使用中',
      },
      deactivateAccount: {
        primary: '停用帳戶',
        secondary: '關閉您的帳戶並取消連結您的裝置。',
        enterPass: '輸入您的密碼',
      },
      activeLanguage: '使用中語言',
      defaultLanguage: '系統語言',
      privacyPolicy: {
        text: '隱私權政策',
      },
      settings: '設定',
      lang: {
        en: '英文',
        it: '義大利文',
        de: '德文',
        esar: '西班牙文（阿根廷）',
        fr: '法文',
        ja: '日文',
        ro: '羅馬尼亞文',
        da: '丹麥文',
        nl: '荷蘭文',
        ca: '卡達隆尼亞文',
        lb: '盧森堡文',
        no: '挪威文',
        ptbr: '葡萄牙文（巴西）',
        zhcn: '中文',
        ms: '馬來文',
        ta: '坦米爾文',
        bs: '波士尼亞文',
        bg: '保加利亞文',
        hr: '克羅埃西亞文',
        tr: '土耳其文',
        cs: '捷克文',
        et: '愛沙尼亞文',
        fi: '芬蘭文',
        el: '希臘文',
        hu: '匈牙利文',
        ga: '愛爾蘭文',
        is: '冰島文',
        lv: '拉脫維亞文',
        lt: '立陶宛文',
        mt: '馬爾他文',
        pl: '波蘭文',
        pt: '葡萄牙文（葡萄牙）',
        ru: '俄文',
        sr: '塞爾維亞文',
        sk: '斯洛伐克文',
        sl: '斯洛維尼亞文',
        sv: '瑞典文',
        mi: '毛利文',
        ar: '阿拉伯文',
        af: '南非荷蘭文',
        zhtw: '中文（繁體）',
        es: '西班牙文（西班牙）',
        ko: '韓文',
      },
      language: {
        primary: '語言',
      },
    },
    show: {
      more: ' 顯示更多',
      less: ' 顯示較少',
    },
    showPage: {
      seasons: '季節',
      cast: '演員',
      producers: '製作人',
      genre: '類型',
      directedBy: '導演',
      season: '季',
    },
    accountDetails: {
      firstName: '名字',
      lastName: '姓氏',
      email: '電子郵件',
      password: '密碼',
      changePassword: '變更密碼',
      forgotPassword: '忘記密碼',
    },
    childPinPopup: {
      title: '兒童保護',
      subTitle: '為兒童保護建立（更新）PIN',
      pinUpdated: '成功更新 PIN',
      pinIsIncorrect: 'PIN 不正確',
    },
    deactivateAccount: {
      title: '您是否確定要停用您的帳戶？',
      description: '一旦您停用之後，您將退出所有服務，並且再也無法檢視車內的內容',
      deactivateButton: '停用帳戶',
    },
    servicesPage: {
      add: '新增至服務',
      remove: '從服務中移除',
      overflow: {
        title: '已選取的套件',
        subtitle: '選取您的服務',
        description: '將您所使用的每項服務新增至您的指南',
      },
    },
    manageProfile: {
      text: '管理個人資料',
    },
    watchlist: {
      title: '以下是您已新增至關注清單的所有電影和節目',
      noTitles: '您的關注清單目前沒有標題',
      addText: '要新增，請在您想要日後返回觀看的任何節目上按一下 + 新增至關注清單。',
      subtext: {
        start: '要新增，請對 ',
        toWatchList: '您想要日後返回觀看的任何節目',
        end: '按一下新增至關注清單。',
      },
    },
    streamingService: {
      title: '選擇串流服務',
      subTitle: '新增您已選取的服務，以顯示在您的電視指南中',
      streamingServices: '串流服務',
    },
    profileUpdate: {
      backToSettings: '返回設定',
      updatedSuccess: '已成功更新使用者',
      apply: '套用',
      wrongPassword: '密碼錯誤',
    },
    changePasswordPage: {
      backToSettings: '返回設定',
      currentPass: '目前密碼',
      newPass: '新密碼',
      confirmPass: '確認密碼',
      change: '變更',
      changeUserInfo: '變更使用者資訊',
      passwordDoNotMatch: '密碼不符或無效',
      passwordUpdated: '已成功更新密碼',
    },
    noServices: {
      title: '您的電視指南目前沒有任何服務',
      text: '要充分利用 ScreenHits TV，您需要新增服務。請按一下這裡來新增您已訂閱的資料流，無需額外費用。如果您目前沒有訂閱任何串流服務，請在這裡新增我們免費的搭售方案，並開始探索BBC、ITV、My5、Channel 4、UKTV 和 STV 的精彩內容。',
      button: '新增服務',
    },
    deleteProfile: {
      cantDeleteProfile: '您無法刪除您目前的個人資料',
      areYouSure: '您是否確定要刪除此個人資料？',
      delete: '刪除',
    },
    notFoundPage: {
      title: '發生錯誤',
      text: '不過別擔心，一切都很好。 我們將返回此頁面。',
      home: '首頁',
    },
  },
};

export default ZH_TW_RESOURCE;
