const CS_RESOURCE = {
  translation: {
    loading: 'Načítání',
    search: {
      cancel: 'Zrušit',
      noResults:
        'Nebyly nalezeny žádné výsledky vyhledávání, ale ty uvedené níže určitě stojí za prohlédnutí.',
    },
    menu: {
      switchProfiles: 'Změnit profil',
      settings: 'Nastavení',
      close: 'Zavřít',
      logOut: 'Odhlášení',
    },
    button: {
      apply: 'Aplikovat',
      continue: 'Pokračovat',
      cancel: 'Zrušit',
      confirm: 'Potvrdit',
      play: 'Přehrát video',
      addToWatchList: 'Přidat do seznamu sledovaných zařízení',
      removeFromWatchList: 'Odstranit ze seznamu sledovaných zařízení',
      moreInfo: 'Více informací',
      resume: 'Obnovit',
      close: 'Zavřít',
    },
    login: {
      header: 'Přihlášení do účtu',
      title: 'Vítejte v',
      subTitle:
        'Využijte své předplatné streamování s Netflixem, Disney, Amazonem a dalšími službami naplno.',
      text: 'Všechno, co chcete sledovat, na jednom místě.',
      button: 'Přihlášení',
      termsOfUse: 'Podmínky použití',
      placeholder: {
        username: 'Uživatelské jméno',
        password: 'Heslo',
      },
      errors: {
        email: {
          noValid: 'E-mail musí být platný',
          required: 'E-mail je povinné pole',
        },
        password: {
          required: 'Heslo je povinné pole',
        },
        wrongPass: {
          first:
            'Ale ne, to nebylo správné heslo. Zkuste to prosím znovu nebo klepněte na tlačítko',
          second: '“Zapomenuté heslo” ',
          third: 'níže.',
        },
      },
    },
    forgotPassword: {
      message:
        'Nemějte obavy. Obnovení hesla je snadné. Stačí zadat svoji e-mailovou adresu níže a kliknout na tlačítko „ODESLAT“',
      button: 'ODESLAT',
      placeholder: {
        username: 'Uživatelské jméno',
      },
      errors: {
        email: {
          required: 'E-mail je povinné pole',
        },
        emailNotRegister: 'E-mailová adresa není zaregistrována!',
        emailSent: 'Odeslání e-mailu s obnovením hesla!',
      },
    },
    profilesPage: {
      title: 'Vybrat profil',
      subTitle: 'Každý ve vašem autě může mít personalizovaný televizní zážitek. Přidejte nový profil.',
      newProfile: 'Nový profil',
    },
    newProfile: {
      isKid: 'Dítě?',
      loading: 'Načítání',
      profileCreated: 'Úspěšně vytvořený profil!',
      profileSelection: 'Výběr profilu',
    },
    settings: {
      accountDetails: {
        primary: 'Podrobnosti o účtu',
        secondary: 'Upravit jméno držitele účtu a e-mail.',
      },
      manageProfiles: {
        primary: 'Správa profilů',
        secondary: 'Upravit podrobnosti',
      },
      parentControl: {
        primary: 'Rodičovská kontrola',
        secondary: 'Vytvoření / změna PIN kódu',
        instruction: '(Při otevřené klávesnici zadejte 4 číslice)',
        recoverPin: 'Obnovení kódu PIN',
        pinSentTo: 'PIN kód odeslán na adresu',
      },
      membershipStatus: {
        primary: 'Stav členství',
        secondary: 'Aktivní',
      },
      deactivateAccount: {
        primary: 'Deaktivace účtu',
        secondary: 'Zavřete svůj účet a zrušte propojení zařízení.',
        enterPass: 'Zadejte své heslo',
      },
      activeLanguage: 'Aktivní jazyk',
      defaultLanguage: 'Systémový jazyk',
      privacyPolicy: {
        text: 'Zásady ochrany osobních údajů',
      },
      settings: 'Nastavení',
      lang: {
        en: 'Angličtina',
        it: 'Italština',
        de: 'Němčina',
        esar: 'Španělština(Argentinský)',
        fr: 'Francouzština',
        ja: 'Japonština',
        ro: 'Rumunština',
        da: 'Dánština',
        nl: 'Holandština',
        ca: 'Katalánština',
        lb: 'Lucemburština',
        no: 'Norština',
        ptbr: 'Portugaština  (Brazílie)',
        zhcn: 'Čínština',
        ms: 'Malajština',
        ta: 'Tamilština',
        bs: 'Bosenština ',
        bg: 'Bulharština',
        hr: 'Chorvatská',
        tr: 'Turečtina',
        cs: 'Čeština',
        et: 'Estonština',
        fi: 'Finština',
        el: 'Řečtina',
        hu: 'Maďarština',
        ga: 'Irština',
        is: 'Islandština',
        lv: 'Lotyština',
        lt: 'Litevština',
        mt: 'Maltština ',
        pl: 'Polština',
        pt: 'Portugalština(Portugalský)',
        ru: 'Ruština',
        sr: 'Srbština',
        sk: 'Čeština(SK)',
        sl: 'Čeština(SL)',
        sv: 'Švédština',
        mi: 'Maorština',
        ar: 'Arabština',
        af: 'Africké dialekty',
        zhtw: 'Čínština(Tradiční)',
        es: 'Španělština(Španělsko)',
        ко: 'Korejština',
      },
      language: {
        primary: 'Jazyk',
      },
    },
    show: {
      more: ' Zobrazit více',
      less: ' Zobrazit méně',
    },
    showPage: {
      seasons: 'Sezóny',
      cast: 'Obsazení',
      producers: 'Výrobci',
      genre: 'Žánr',
      directedBy: 'Režie',
      season: 'Sezóna',
    },
    accountDetails: {
      firstName: 'Křestní jméno',
      lastName: 'Příjmení',
      email: 'E-mail',
      password: 'Heslo',
      changePassword: 'Změna hesla',
      forgotPassword: 'Zapomenuté heslo',
    },
    childPinPopup: {
      title: 'Ochrana dětí',
      subTitle: 'Vytvoření (aktualizace) kódu PIN pro ochranu dětí',
      pinUpdated: 'PIN kód byl úspěšně aktualizován',
      pinIsIncorrect: 'PIN je nesprávný',
    },
    deactivateAccount: {
      title: 'Jste si jisti, že chcete deaktivovat svůj účet?',
      description:
        'Když tak učiníte, budete odhlášeni ze všech služeb a nebudete moci prohlížet obsah ve svém autě.',
      deactivateButton: 'Deaktivovat účet',
    },
    servicesPage: {
      add: 'Přidat do služeb',
      remove: 'Odebrat ze služeb',
      overflow: {
        title: 'Vybrané balíčky',
        subtitle: 'Vyberte své služby',
        description: 'Přidejte do průvodce všechny služby, které používáte',
      },
    },
    manageProfile: {
      text: 'Správa profilů',
    },
    watchlist: {
      title: 'Zde jsou všechny filmy a seriály, které jste přidali do svého seznamu sledovaných.',
      noTitles: 'Momentálně nemáte v seznamu sledovaných titulů žádné tituly',
      addText:
        'Chcete-li přidat, klepněte na + Přidat do seznamu sledovaných pořadů, ke kterým se chcete vrátit a prohlédnout si je později.',
      subtext: {
        start: 'Chcete-li přidat, klikněte na ',
        toWatchList: 'Přidat do seznamu sledovaných zařízení',
        end: 'nebo jakýkoli program, ke kterému byste se chtěli vrátit a prohlédnout si jej později.',
      },
    },
    streamingService: {
      title: 'Výběr streamovacích služeb',
      subTitle: 'Přidání vybraných služeb, které se zobrazí v televizním průvodci',
      streamingServices: 'Streamovací služby',
    },
    profileUpdate: {
      backToSettings: 'Zpět na nastavení',
      updatedSuccess: 'Uživatel byl úspěšně aktualizován',
      apply: 'Aplikovat',
      wrongPassword: 'Nesprávné heslo',
    },
    changePasswordPage: {
      backToSettings: 'Zpět na nastavení',
      currentPass: 'Aktuální heslo',
      newPass: 'Nové heslo',
      confirmPass: 'Potvrzení hesla',
      change: 'Změna',
      changeUserInfo: 'Změna informací o uživateli',
      passwordDoNotMatch: 'Hesla se neshodují nebo jsou neplatná',
      passwordUpdated: 'Heslo bylo úspěšně aktualizováno',
    },
    noServices: {
      title: 'Momentálně nemáte ve svém TV programu žádné služby',
      text: 'Chcete-li ze ScreenHits TV vytěžit maximum, musíte přidat služby. Klikněte sem a přidejte streamery, které již odebíráte, a to bez dalších poplatků. A pokud momentálně nejste předplatitelem žádné streamovací služby, přidejte si náš BEZPLATNÝ balíček a začněte objevovat skvělý obsah.',
      button: 'Přidat služby',
    },
    deleteProfile: {
      cantDeleteProfile: 'Nemůžete odstranit svůj aktuální profil',
      areYouSure: 'Opravdu chcete odstranit tento profil?',
      delete: 'Odstranit',
    },
    notFoundPage: {
      title: 'Něco se pokazilo',
      text: 'Ale nebojte se, vše je v pořádku. S touto stránkou se vrátíme.',
      home: 'Úvodní stránka',
    },
  },
};

export default CS_RESOURCE;
