import { apiClient } from 'api/apiClient';
import { checkConnection, miscApiUrl } from 'api/miscApi';
import NoInternetPlaceholder from 'components/ErrorPlaceholders/NoInternetPlaceholder';

import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

interface Props {
  children: React.ReactNode;
}

const NoInternetWrapper: React.FC<Props> = ({ children }: Props) => {
  const { error } = useSWR(miscApiUrl.checkConnection, checkConnection, {
    refreshInterval: 10000,
    revalidateIfStale: true,
    refreshWhenOffline: true,
    refreshWhenHidden: true,
  });

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const intervalID = setInterval(async () => {
      try {
        await apiClient.get(miscApiUrl.checkConnection, {
          timeout: 10000,
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });
        setIsOnline(true);
      } catch (err) {
        setIsOnline(false);
      }
    }, 3000);

    return () => clearInterval(intervalID);
  }, [error]);

  // if (!isOnline) {
  //   return <NoInternetPlaceholder />;
  // }

  // return <>{children}</>;

  // const [isOnline, setIsOnline] = useState(navigator.onLine);
  // useEffect(() => {
  //   function onlineHandler() {
  //     setIsOnline(true);
  //   }
  //   function offlineHandler() {
  //     setIsOnline(false);
  //   }
  //   window.addEventListener('online', onlineHandler);
  //   window.addEventListener('offline', offlineHandler);
  //   return () => {
  //     window.removeEventListener('online', onlineHandler);
  //     window.removeEventListener('offline', offlineHandler);
  //   };
  // }, []);

  if (isOnline) {
    return <>{children}</>;
  }

  return <NoInternetPlaceholder />;
};

export default NoInternetWrapper;
