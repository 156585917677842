import React, { useEffect, useRef } from 'react';

import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import './styles.scss';

import videojsContribQualityLevels from 'videojs-contrib-quality-levels';
import videojsHlsQualitySelector from 'videojs-hls-quality-selector';
import useAxiosRequest from 'hooks/useApiRequest';
import statsApi from 'api/statsApi';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

videojs.registerPlugin('videojsContribQualityLevels', videojsContribQualityLevels);
videojs.registerPlugin('videojsHlsQualitySelector', videojsHlsQualitySelector);

interface VideoPlayerProps {
  options: videojs.PlayerOptions;
  onReady: (player: videojs.Player) => void;
  title: string | null | boolean;
}

const SeekBar = videojs.getComponent('SeekBar');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
SeekBar.prototype.getPercent = function getPercent() {
  const time = this.player_.currentTime();
  const percent = time / this.player_.duration();
  return percent >= 1 ? 1 : percent;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
SeekBar.prototype.handleMouseMove = function handleMouseMove(event) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let newTime = this.calculateDistance(event) * this.player_.duration();
  if (newTime === this.player_.duration()) {
    newTime = newTime - 0.1;
  }
  this.player_.currentTime(newTime);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.update();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentTime = player.currentTime();
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime - minutes * 60);
  const x = minutes < 10 ? '0' + minutes : minutes;
  const y = seconds < 10 ? '0' + seconds : seconds;
  const format = x + ':' + y;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  player.controlBar.currentTimeDisplay.el_.innerHTML = format;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ options, onReady, title }: VideoPlayerProps) => {
  const playerRef = useRef<videojs.Player | null>(null);
  const videoRef = useRef<HTMLDivElement | null>(null);

  const { id: mediaId } = useParams();
  const navigate = useNavigate();
  const { requestFunc } = useAxiosRequest();

  const [searchParams] = useSearchParams();

  const backPath = searchParams.get('backPath');
  const channelId = Number(searchParams.get('channelId'));

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current != null) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add(
        'vjs-shtv-nio-theme',
        'vjs-user-active',
        'vjs-user-inactive',
        'vjs-4-3',
      );

      videoRef?.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          techOrder: ['html5'],
          preload: 'none',
          bigPlayButton: false,
          html5: {
            handleManifestRedirects: true,
            enableLowInitialPlaylist: true,
            hls: {
              overrideNative: true,
            },
          },
          fill: false,
          fluid: true,
          muted: true,
          liveui: true,
          aspectRatio: '4:3',
          autoplay: false,
          responsive: true,
          controls: true,
          autoSetup: true,
          controlBar: {
            playToggle: false,
            volumePanel: false,
            progressControl: {
              seekBar: true,
            },
            remainingTimeDisplay: true,
            currentTimeDisplay: false,
            timeDivider: false,
            durationDisplay: false,
            fullscreenToggle: true,
            pictureInPictureToggle: false,
            audioTrackButton: false,
          },
          inactivityTimeout: 5000,
          nativeControlsForTouch: false,
          plugins: {
            videojsContribQualityLevels: {},
            videojsHlsQualitySelector: { displayCurrentQuality: true, placementIndex: 9 },
          },
          ...options,
        },
        () => {
          onReady && onReady(player);
        },
      ));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      const volumeLevel = localStorage.getItem('_vl');

      if (volumeLevel) {
        player?.volume(Number(volumeLevel));
      } else {
        player?.volume();
      }
    }
  }, [videoRef, options, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    async function onPlayPlayer() {
      const promise = await player?.play();
      if (promise === undefined) {
        player?.muted(false);
      }
    }

    onPlayPlayer();

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]); 

  useEffect(() => {
    const currentDateTime = new Date();

    const player = playerRef.current;
    const overlay = document.createElement('div');

    const preloader = document.createElement('div');

    const overlayContent = document.createElement('div');
    const playingControl = document.createElement('div');

    const topToolbar = document.createElement('div');
    const bottomToolbar = document.createElement('div');

    const togglePlayButton = document.createElement('button');
    const backButton = document.createElement('button');
    const forwardButton = document.createElement('button');

    // const volumeControl = document.createElement('input');

    const closeButton = document.createElement('button');

    const mediaTitle = document.createElement('h1');

    const t = title as unknown as string;
    const maxLength = 27;
    mediaTitle.innerHTML = t.length > 0 
        ? (t.length > maxLength ? `${t.substring(0, maxLength)}...` : t)         
        : '';       
    
    preloader.className = 'vjs-preloader';
    closeButton.className = 'shtv-close-button';

    topToolbar.className = 'shtv-top-toolbar';
    bottomToolbar.className = 'shtv-bottom-toolbar';

    overlay.className = 'vjs-touch-overlay';
    overlayContent.className = 'vjs-touch-wrapper';
    playingControl.className = 'shtv-playing-control';

    togglePlayButton.className = 'shtv-toggle-play-button';

    backButton.className = 'shtv-seek-back';
    forwardButton.className = 'shtv-seek-forward';

    // volumeControl.type = 'range';
    // volumeControl.className = 'shtv-volume-control';
    // volumeControl.min = '0';
    // volumeControl.max = '1';
    // volumeControl.step = '0.01';

    // const volumeLevel = localStorage.getItem('_vl');

    // if (volumeLevel) {
    //   volumeControl.value = volumeLevel;
    // } else {
    //   volumeControl.value = '0.5';
    // }

    closeButton.innerHTML =
      '<div><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.79718 6.26164L23.9998 20.4637L38.2028 6.26164C38.5633 5.90116 39.1305 5.87343 39.5228 6.17845L39.617 6.26164L41.7383 8.38296C42.0988 8.74345 42.1265 9.31068 41.8215 9.70297L41.7383 9.79718L27.5347 23.9998L41.7383 38.2028C42.1288 38.5933 42.1288 39.2265 41.7383 39.617L39.617 41.7383C39.2265 42.1288 38.5933 42.1288 38.2028 41.7383L23.9998 27.5347L9.79718 41.7383C9.43669 42.0988 8.86946 42.1265 8.47717 41.8215L8.38296 41.7383L6.26164 39.617C5.90116 39.2565 5.87343 38.6893 6.17845 38.297L6.26164 38.2028L20.4637 23.9998L6.26164 9.79718C5.87112 9.40665 5.87112 8.77349 6.26164 8.38296L8.38296 6.26164C8.77349 5.87112 9.40665 5.87112 9.79718 6.26164Z" fill="white"/></svg></div>';

    togglePlayButton.addEventListener('click', () => {
      if (player?.paused()) {
        player?.play();
      } else {
        player?.pause();
      }     
    });

    backButton.addEventListener('click', () => {
      if (player?.currentTime) {
        player?.currentTime(player.currentTime() - 10);
      }      
    });

    forwardButton.addEventListener('click', () => {
      if (player?.currentTime) {
        player?.currentTime(player.currentTime() + 10);
      }
    });

    closeButton.addEventListener('click', async () => {

      if (backPath) {
        navigate({
          pathname: backPath,
          search: mediaId && channelId ? `mediaId=${mediaId}&channelId=${channelId}` : '',
        });
      } else {
        navigate(-1);
      }   
 
      if(player?.liveTracker?.isLive() == true) {
        const duration = (new Date().getTime() - currentDateTime.getTime()) / 1000;     

        await requestFunc(
          statsApi.createStatParam([
            {
              Event: 'LiveEnd',
              ChannelId: channelId,
              TimeCode: duration
            },
          ]),
        );
      }
    });

    if (overlay) {
      overlay.appendChild(overlayContent);
      
      overlayContent.appendChild(preloader);

      topToolbar.appendChild(closeButton);

      bottomToolbar.appendChild(mediaTitle);

      playingControl.appendChild(backButton);
      playingControl.appendChild(togglePlayButton);
      playingControl.appendChild(forwardButton);

      overlayContent.appendChild(topToolbar);

      overlayContent.appendChild(playingControl);

      overlayContent.appendChild(bottomToolbar);
    }

    const videoJs = document.getElementsByClassName('vjs-shtv-nio-theme');
    videoJs[0].appendChild(overlay);
  }, [navigate, backPath, channelId, mediaId, title]);

  return (
    <div
      data-vjs-player
      style={{
        position: 'relative',
      }}
    >
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;
