const AR_RESOURCE = {
  translation: {
    loading: 'تحميل',
    search: {
      cancel: 'إلغاء',
      noResults:
        'لم يتم العثور على نتائج بحث، ولكن النتائج الموجودة أدناه تستحق المراجعة بالتأكيد.',
    },
    menu: {
      switchProfiles: 'تبديل الملفات الشخصية',
      settings: 'الإعدادات',
      close: 'إغلاق',
      logOut: 'تسجيل خروج',
    },
    button: {
      apply: 'تطبيق',
      continue: 'استمرار',
      cancel: 'إلغاء',
      confirm: 'تأكيد',
      play: 'تشفيل',
      addToWatchList: 'إضافة إلى قائمة المشاهدة',
      removeFromWatchList: 'إزالة من قائمة المشاهدة',
      moreInfo: 'مزيد من المعلومات',
      resume: 'استئناف',
      close: 'إغلاق',
    },
    login: {
      header: 'تسجيل الدخول للحساب',
      title: 'مرحبًا بك في',
      subTitle: 'احصل على أقصى استفادة من اشتراكات البث الخاصة بك مع Disney و Amazon والمزيد.',
      text: 'كل ما تريد مشاهدته في مكان واحد.',
      button: 'تسجيل الدخول',
      termsOfUse: 'شروط الاستخدام',
      placeholder: {
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
      },
      errors: {
        email: {
          noValid: 'يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صحيح',
          required: 'حقل البريد الإلكتروني مطلوب',
        },
        password: {
          required: 'كلمة المرور حقل مطلوب',
        },
        wrongPass: {
          first:
            'عفوًا، لم تكن هذه هي كلمة المرور الصحيحة. يرجى المحاولة مرة أخرى أو النقر فوق زر ',
          second: '"نسيت كلمة المرور"',
          third: 'أدناه.',
        },
      },
    },
    forgotPassword: {
      message:
        'لا تقلق. إعادة تعيين كلمة المرور الخاصة بك أمر سهل. فقط أدخل عنوان بريدك الإلكتروني أدناه وانقر على "إرسال"',
      button: '"إرسال"',
      placeholder: {
        username: 'اسم المستخدم',
      },
      errors: {
        email: {
          required: 'حقل البريد الإلكتروني مطلوب',
        },
        emailNotRegister: 'عنوان البريد الإلكتروني غير مسجل!',
        emailSent: 'تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور!',
      },
    },
    profilesPage: {
      title: 'من يشاهد؟',
      subTitle: 'الجميع في سيارتك يمكنهم الاستمتاع بتجربة تلفزيون شخصية. أضف ملف تعريف جديد.',
      newProfile: 'ملف شخصي جديد',
    },
    newProfile: {
      isKid: 'أطفال؟',
      loading: 'تحميل',
      profileCreated: 'تم إنشاء الملف الشخصي بنجاح!',
      profileSelection: 'اختيار الملف الشخصي',
    },
    settings: {
      accountDetails: {
        primary: 'تفاصيل الحساب',
        secondary: 'تعديل اسم صاحب الحساب والبريد الإلكتروني.',
      },
      manageProfiles: {
        primary: 'إدارة الملفات الشخصية',
        secondary: 'تعديل التفاصيل',
      },
      parentControl: {
        primary: 'الرقابة الأبوية',
        secondary: 'إنشاء رقم سري / تغيير الرقم السري',
        instruction: '(الرجاء إدخال 4 أرقام أثناء فتح لوحة المفاتيح)',
        recoverPin: 'استعادة الرقم السري',
        pinSentTo: 'تم إرسال الرقم السري إلى',
      },
      membershipStatus: {
        primary: 'حالة العضوية',
        secondary: 'نشطة',
      },
      deactivateAccount: {
        primary: 'تعطيل الحساب',
        secondary: 'أغلق حسابك وقم بإلغاء ربط أجهزتك.',
        enterPass: 'أدخل كلمة مرورك',
      },
      privacyPolicy: {
        text: 'اللغة النشطة',
      },
      activeLanguage: 'لغة النظام',
      defaultLanguage: 'سياسة الخصوصية',
      settings: 'الإعدادات',
      lang: {
        en: 'الإنجليزية',
        it: 'الإيطالية',
        de: 'الألمانية',
        esar: 'الإسبانية (الأرجنتين)',
        fr: 'الفرنسية',
        ja: 'اليابانية',
        ro: 'الرومانية',
        da: 'الدانماركية',
        nl: 'الهولندية',
        ca: 'الكاتالونية',
        lb: 'اللوكسمبرجية',
        no: 'النرويجية',
        ptbr: 'البرتغالية (البرازيل)',
        zhcn: 'الصينىة',
        ms: 'الملايو',
        ta: 'التاميلية',
        bs: 'البوسنية',
        bg: 'البلغارية',
        hr: 'الكرواتية',
        tr: 'التركية',
        cs: 'التشيكية',
        et: 'الإستونية',
        fi: 'الفنلندية',
        el: 'اليونانية',
        hu: 'المجرية',
        ga: 'الأيرلندية',
        is: 'الآيسلندية',
        lv: 'اللاتفية',
        lt: 'الليتوانية',
        mt: 'المالطية',
        pl: 'البولندية',
        pt: 'البرتغالية (البرتغال)',
        ru: 'الروسية',
        sr: 'الصربية',
        sk: 'السلوفاكية',
        sl: 'السلوفينية',
        sv: 'السويدية',
        mi: 'الماورية',
        ar: 'العربية',
        af: 'الأفريكانية',
        zhtw: 'الصينية (التقليدية)',
        es: 'الإسبانية (إسبانيا)',
        ko: 'الكورية',
      },
      language: {
        primary: 'اللغة',
      },
    },
    show: {
      more: ' عرض المزيد',
      less: ' عرض أقل',
    },
    showPage: {
      seasons: 'المواسم',
      cast: 'الفريق',
      producers: 'المنتجين',
      genre: 'النوع',
      directedBy: 'من إخراج',
      season: 'الموسم',
    },
    accountDetails: {
      firstName: 'الاسم الأول',
      lastName: 'اسم العائلة',
      email: 'البريد الإلكتروني',
      password: 'كلمة المرور',
      changePassword: 'تغيير كلمة المرور',
      forgotPassword: 'نسيت كلمة المرور',
    },
    childPinPopup: {
      title: 'حماية الطفل',
      subTitle: 'قم بإنشاء (تحديث) الرقم السري لحماية الطفل',
      pinUpdated: 'تم تحديث الرقم السري بنجاح',
      pinIsIncorrect: 'الرقم السري غير صحيح',
    },
    deactivateAccount: {
      title: 'هل أنت متأكد أنك تريد إلغاء تنشيط حسابك؟',
      description:
        'بمجرد القيام بذلك، سيتم تسجيل خروجك من جميع الخدمات ولن تتمكن بعد ذلك من عرض المحتوى في سيارتك',
      deactivateButton: 'تعطيل الحساب',
    },
    servicesPage: {
      add: 'أضف إلى الخدمات',
      remove: 'إزالة من الخدمات',
      overflow: {
        title: 'حزم مختارة',
        subtitle: 'حدد خدماتك',
        description: 'أضف كل خدمة تستخدمها إلى دليلك',
      },
    },
    manageProfile: {
      text: 'إدارة الملفات الشخصية',
    },
    watchlist: {
      title: 'إليك جميع الأفلام والبرامج التي أضفتها إلى قائمة المشاهدة',
      noTitles: 'ليس لديك حاليًا أي عناوين في قائمة الرغبات الخاصة بك',
      addText:
        'للإضافة، يرجى النقر فوق + إضافة إلى قائمة المشاهدة لأي برنامج ترغب في العودة إليه ومشاهدته في وقت لاحق.',
      subtext: {
        start: 'للإضافة، الرجاء الضغط على ',
        toWatchList: 'إضافة إلى قائمة المشاهدة',
        end: 'لأي برنامج تود العودة إليه ومشاهدته في وقت لاحق.',
      },
    },
    streamingService: {
      title: 'اختر خدمات البث',
      subTitle: 'أضف الخدمات التي اخترتها لتظهر في دليل التلفزيون الخاص بك',
      streamingServices: 'خدمات البث',
    },
    profileUpdate: {
      backToSettings: 'رجوع إلى الإعدادات',
      updatedSuccess: 'تم تحديث المستخدم بنجاح',
      apply: 'تطبيق',
      wrongPassword: 'كلمة المرور خاطئة',
    },
    changePasswordPage: {
      backToSettings: 'رجوع إلى الإعدادات',
      currentPass: 'كلمة المرور الحالية',
      newPass: 'كلمة المرور الجديدة',
      confirmPass: 'تأكيد كلمة المرور',
      change: 'تغيير',
      changeUserInfo: 'تغيير معلومات المستخدم',
      passwordDoNotMatch: 'كلمتا المرور غير متطابقين أو غير صيحتين',
      passwordUpdated: 'تم تحديث كلمة المرور بنجاح',
    },
    noServices: {
      title: 'ليس لديك حاليًا أي خدمات في دليل التلفزيون الخاص بك',
      text: 'لتحقيق أقصى استفادة من ScreenHits TV، تحتاج إلى إضافة خدمات. يُرجى النقر هنا لإضافة قنوات البث التي اشتركت فيها بالفعل دون أي تكلفة إضافية. وإذا لم تكن مشتركًا حاليًا في أي خدمة بث، فأضف الحزمة المجانية هنا وابدأ في اكتشاف محتوى رائع من BBC و ITV و My5 و Channel 4 و UKTV و STV..',
      button: 'أضف خدمات',
    },
    deleteProfile: {
      cantDeleteProfile: 'لا يمكنك حذف ملفك الشخصي الحالي',
      areYouSure: 'هل تريد بالتأكيد حذف هذا الملف الشخصي؟',
      delete: 'حذف',
    },
    notFoundPage: {
      title: 'حدث خطأ ما',
      text: 'لا تقلق رغم ذلك ، كل شيء على ما يرام. سنعود بهذه الصفحة.',
      home: 'الرئيسية',
    },
  },
};

export default AR_RESOURCE;
