import { useEffect } from 'react';
import { Box } from '@mui/material';

import { Outlet } from 'react-router-dom';

import { useAppContext } from 'store';
import useSWR from 'swr';
import { getWatchList, watchListApiUrl } from 'api/watchListApi';
import { SetIsPasswordEntered, setWatchList } from 'store/actions';

// import ProgressLoader from 'components/Loaders/ProgressLoader';
import { getIsPasswordEntered } from 'helpers/sessionStorage';
import BackdropLoader from 'components/Loaders/BackdropLoader';

const isPasswordEntered = getIsPasswordEntered();

const ProfileLayout: React.FC = (): JSX.Element => {
  const { data: watchListData, error } = useSWR(watchListApiUrl, getWatchList);

  const { dispatch } = useAppContext();

  useEffect(() => {
    if (watchListData && watchListData?.length > 0) {
      dispatch(setWatchList(watchListData));
    } else {
      dispatch(setWatchList(undefined));
    }
  }, [dispatch, watchListData]);

  useEffect(() => {
    if (isPasswordEntered != null) {
      dispatch(SetIsPasswordEntered('true'));
    }
  }, [dispatch]);

  if (!watchListData && !error) {
    return <BackdropLoader />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Outlet />
    </Box>
  );
};

export default ProfileLayout;
