const LV_RESOURCE = {
  translation: {
    loading: 'Notiek ielāde...',
    search: {
      cancel: 'Atcelt',
      noResults: 'Nav atrasts neviens meklēšanas rezultāts, bet tālāk norādītos ir vērts apskatīt.',
    },
    menu: {
      switchProfiles: 'Pārslēgt profilus',
      settings: 'Iestatījumi',
      close: 'Aizvērt',
      logOut: 'Beigt sesiju',
    },
    button: {
      apply: 'Lietot',
      continue: 'Turpināt',
      cancel: 'Atcelt',
      confirm: 'Apstiprināt',
      play: 'Atskaņot',
      addToWatchList: 'Pievienot skatīšanās sarakstam',
      removeFromWatchList: 'Izņemt no skatīšanās saraksta',
      moreInfo: 'Vairāk informācijas',
      resume: 'Atsākt',
      close: 'Aizvērt',
    },
    login: {
      header: 'Konta pieteikšanās dati',
      title: 'Laipni lūdzam',
      subTitle:
        'Izmantojiet visas savu straumēšanas abonementu iespējas ar Disney, Amazon un citiem.',
      text: 'Viss, ko vēlaties skatīties, vienuviet.',
      button: 'Pieteikties',
      termsOfUse: 'Lietošanas noteikumi',
      placeholder: {
        username: 'Lietotājvārds',
        password: 'Parole',
      },
      errors: {
        email: {
          noValid: 'E-pastam ir jābūt derīgai e-pasta adresei',
          required: 'E-pasta lauks ir obligāti jāaizpilda',
        },
        password: {
          required: 'Paroles lauks ir obligāti jāaizpilda',
        },
        wrongPass: {
          first: 'Ui, tā nebija pareiza parole! Lūdzu, mēģiniet vēlreiz vai noklikšķiniet uz ',
          second: 'pogas “Aizmirsta parole”  ',
          third: 'zemāk.',
        },
      },
    },
    forgotPassword: {
      message:
        'Uztraukumam nav pamata. Paroles atiestatīšana ir vienkārša. Gluži vienkārši tālāk ievadiet savu e-pasta paroli un noklikšķiniet uz “SŪTĪT”',
      button: 'SŪTĪT',
      placeholder: {
        username: 'Lietotājvārds',
      },
      errors: {
        email: {
          required: 'E-pasta lauks ir obligāti jāaizpilda',
        },
        emailNotRegister: 'E-pasta adrese nav reģistrēta!',
        emailSent: 'E-pasta vēstule paroles atiestatīšanai nosūtīta!',
      },
    },
    profilesPage: {
      title: 'Kas skatās?',
      subTitle:
        'Ikviens jūsu automašīnā var piedzīvot personalizētu televīzijas pieredzi. Pievienojiet jaunu profilu.',
      newProfile: 'Jauns profils',
    },
    newProfile: {
      isKid: 'Bērni?',
      loading: 'Notiek ielāde...',
      profileCreated: 'Profils veiksmīgi izveidots!',
      profileSelection: 'Profila atlase',
    },
    settings: {
      accountDetails: {
        primary: 'Konta dati',
        secondary: 'Rediģējiet konta īpašnieka vārdu un e-pasta adresi.',
      },
      manageProfiles: {
        primary: 'Pārvaldīt profilus',
        secondary: 'Rediģēt datus',
      },
      parentControl: {
        primary: 'Vecāku kontrole',
        secondary: 'Izveidot PIN / Mainīt PIN',
        instruction: '(Lūdzu, ievadiet 4 ciparus, kamēr tastatūra ir atvērta)',
        recoverPin: 'Atgūt PIN',
        pinSentTo: 'PIN nosūtīts uz',
      },
      membershipStatus: {
        primary: 'Dalības statuss',
        secondary: 'Aktīvs',
      },
      deactivateAccount: {
        primary: 'Deaktivizēt kontu',
        secondary: 'Aizveriet kontu un atsaistiet savas ierīces.',
        enterPass: 'Ievadiet paroli',
      },
      activeLanguage: 'Aktīvā valoda',
      defaultLanguage: 'Sistēmas valoda',
      privacyPolicy: {
        text: 'Privātuma politika',
      },
      settings: 'Iestatījumi',
      lang: {
        en: 'Angļu',
        it: 'Itāliešu',
        de: 'Vācu',
        esar: 'Spāņu(Argentīnas)',
        fr: 'Franču',
        ja: 'Japāņu',
        ro: 'Rumāņu',
        da: 'Dāņu',
        nl: 'Holandiešu',
        ca: 'Katalāniešu',
        lb: 'Luksemburgiešu',
        no: 'Norvēģu',
        ptbr: 'Portugāļu(Brazīlijas)',
        zhcn: 'Ķīniešu',
        ms: 'Malajiešu',
        ta: 'Tamilu',
        bs: 'Bosniešu',
        bg: 'Bulgāru',
        hr: 'Horvātu',
        tr: 'Turku',
        cs: 'Čehu',
        et: 'Igauņu',
        fi: 'Somu',
        el: 'Grieķu',
        hu: 'Ungāru',
        ga: 'Īru',
        is: 'Islandiešu',
        lv: 'Latviešu',
        lt: 'Lietuviešu',
        mt: 'Maltiešu',
        pl: 'Poļu',
        pt: 'Portugāļu(Portugāles)',
        ru: 'Krievu',
        sr: 'Serbu',
        sk: 'Slovāku',
        sl: 'Slovēņu',
        sv: 'Zviedru',
        mi: 'Maoru',
        ar: 'Arābu',
        af: 'Afrikandu',
        zhtw: 'Ķīniešu(Tradicionālā)',
        es: 'Spāņu(Spānijas)',
        ko: 'Korejiešu',
      },
      language: {
        primary: 'Valoda',
      },
    },
    show: {
      more: ' Rādīt vairāk',
      less: ' Rādīt mazāk',
    },
    showPage: {
      seasons: 'Sezonas',
      cast: 'Lomu sadalījums',
      producers: 'Producenti',
      genre: 'Žanrs',
      directedBy: 'Režisors',
      season: 'Sezona',
    },
    accountDetails: {
      firstName: 'Vārds',
      lastName: 'Uzvārds',
      email: 'E-pasts',
      password: 'Parole',
      changePassword: 'Mainīt paroli',
      forgotPassword: 'Aizmirsta parole',
    },
    childPinPopup: {
      title: 'Bērna aizsardzība',
      subTitle: 'Izveidot (atjaunināt) PIN bērna aizsardzībai',
      pinUpdated: 'PIN veiksmīgi atjaunināts',
      pinIsIncorrect: 'PIN ir nepareizs',
    },
    deactivateAccount: {
      title: 'Vai tiešām vēlaties deaktivizēt kontu?',
      description:
        'Pēc tā izdarīšanas jūs būsiet izrakstīts no visiem pakalpojumiem un vairs nevarēsiet skatīt saturu automašīnā.',
      deactivateButton: 'Deaktivizēt kontu',
    },
    servicesPage: {
      add: 'Pievienot pakalpojumiem',
      remove: 'Izņemt no pakalpojumiem',
      overflow: {
        title: 'Atlasītie komplekti',
        subtitle: 'Atlasiet pakalpojumus',
        description: 'Pievienojiet katru lietoto pakalpojumu savam ceļvedim',
      },
    },
    manageProfile: {
      text: 'Pārvaldīt profilus',
    },
    watchlist: {
      title: 'Šeit ir visas filmas un raidījumi, ko esat pievienojis skatīšanās sarakstam.',
      noTitles: 'Jūsu skatīšanās sarakstā pašlaik nav nekādu nosaukumu.',
      addText:
        'Lai pievienotu, lūdzu, noklikšķiniet uz + Pievienot skatīšanās sarakstam uz jebkura raidījuma, pie kura vēlaties vēlāk atgriezties un noskatīties.',
      subtext: {
        start: 'Lai pievienotu, lūdzu, noklikšķiniet uz ',
        toWatchList: 'Pievienot skatīšanās sarakstam',
        end: 'uz jebkura raidījuma, pie kura vēlaties vēlāk atgriezties un noskatīties.',
      },
    },
    streamingService: {
      title: 'Izvēlieties straumēšanas pakalpojumus',
      subTitle: 'Pievienojiet atlasītos pakalpojumus, lai tie parādītos TV programmā.',
      streamingServices: 'Straumēšanas pakalpojumi',
    },
    profileUpdate: {
      backToSettings: 'Atgriezties pie iestatījumiem',
      updatedSuccess: 'Lietotājs ir veiksmīgi atjaunināts',
      apply: 'Lietot',
      wrongPassword: 'Nepareiza parole',
    },
    changePasswordPage: {
      backToSettings: 'Atgriezties pie iestatījumiem',
      currentPass: 'Pašreizējā parole',
      newPass: 'Jauna parole',
      confirmPass: 'Apstipriniet paroli',
      change: 'Mainīt',
      changeUserInfo: 'Mainīt lietotāja informāciju',
      passwordDoNotMatch: 'Paroles nav vienādas vai ir nederīgas',
      passwordUpdated: 'Paroles veiksmīgi atjauninātas',
    },
    noServices: {
      title: 'Jūsu TV programmā pašreiz nav pakalpojumu',
      text: 'Lai iegūtu visu iespējamo no ScreenHits TV, jāpievieno pakalpojumi. Lūdzu, noklikšķiniet šeit, lai bez papildu maksas pievienotu jau abonētos straumētājus. Un, ja pašlaik neesat abonējis nekādu straumēšanas pakalpojumu, pievienojiet mūsu BEZMAKSAS komplektu šeit, lai atklātu lielisku saturu, ko piedāvā BBC, ITV, My5, Channel 4, UKTV un STV.',
      button: 'Pievienot pakalpojumus',
    },
    deleteProfile: {
      cantDeleteProfile: 'Jūs nevarat izdzēst savu pašreizējo profilu',
      areYouSure: 'Vai tiešām vēlaties dzēst šo profilu?',
      delete: 'Dzēst',
    },
    notFoundPage: {
      title: 'Radās kāda problēma',
      text: 'Tomēr neuztraucieties, viss ir kārtībā. Mēs atgriezīsimies ar šo lapu.',
      home: 'Sākums',
    },
  },
};

export default LV_RESOURCE;
