import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const Error404: FC<SvgIconProps> = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width='600px'
      height='330px'
      viewBox='0 0 600 330'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_1_8357'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='140'
        y='38'
        width='317'
        height='282'
      >
        <ellipse
          cx='298.696'
          cy='179.409'
          rx='157.99'
          ry='140.357'
          transform='rotate(3 298.696 179.409)'
          fill='url(#paint0_radial_1_8357)'
        />
      </mask>
      <g mask='url(#mask0_1_8357)'>
        <g opacity='0.738118' filter='url(#filter0_i_1_8357)'>
          <ellipse
            cx='160.841'
            cy='287.731'
            rx='175.706'
            ry='152.764'
            transform='rotate(37 160.841 287.731)'
            fill='url(#paint1_radial_1_8357)'
          />
        </g>
        <g opacity='0.3' filter='url(#filter1_f_1_8357)'>
          <ellipse
            cx='26.6206'
            cy='8.14638'
            rx='26.6206'
            ry='8.14638'
            transform='matrix(-0.819152 -0.573576 -0.573576 0.819152 206.866 156.42)'
            fill='url(#paint2_linear_1_8357)'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M186.457 133.331L174.017 138.718C174.017 138.718 168.572 143.68 179.359 153.031C194.161 165.861 206.576 170.887 223.49 176.236C229.853 178.249 232.019 174.889 232.019 174.889L232.262 163.232L186.457 133.331Z'
          fill='url(#paint3_linear_1_8357)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M186.457 133.331L174.017 138.718C174.017 138.718 168.572 143.68 179.359 153.031C194.161 165.861 206.576 170.887 223.49 176.236C229.853 178.249 232.019 174.889 232.019 174.889L232.262 163.232L186.457 133.331Z'
          fill='url(#paint4_linear_1_8357)'
        />
        <ellipse
          cx='27.4934'
          cy='9.06983'
          rx='27.4934'
          ry='9.06983'
          transform='matrix(-0.819152 -0.573576 -0.573576 0.819152 236.872 157.344)'
          fill='#47474D'
        />
        <ellipse
          cx='20.6212'
          cy='6.80197'
          rx='20.6212'
          ry='6.80197'
          transform='matrix(-0.829038 -0.559193 -0.559193 0.829038 232.973 156.761)'
          fill='#323337'
        />
        <mask
          id='mask1_1_8357'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x='194'
          y='138'
          width='36'
          height='26'
        >
          <ellipse
            cx='20.6212'
            cy='6.80197'
            rx='20.6212'
            ry='6.80197'
            transform='matrix(-0.829038 -0.559193 -0.559193 0.829038 232.973 156.761)'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_1_8357)'>
          <ellipse
            cx='16.8015'
            cy='6.04654'
            rx='16.8015'
            ry='6.04654'
            transform='matrix(-0.819152 -0.573576 -0.573576 0.819152 230.981 160.863)'
            fill='url(#paint5_linear_1_8357)'
          />
        </g>
        <g opacity='0.3' filter='url(#filter2_f_1_8357)'>
          <ellipse
            cx='18.9684'
            cy='5.8185'
            rx='18.9684'
            ry='5.8185'
            transform='matrix(-0.573577 -0.819152 -0.819152 0.573577 322.147 239.186)'
            fill='url(#paint6_linear_1_8357)'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M314.117 218.741L304.462 219.329C304.462 219.329 299.598 221.333 304.537 230.227C311.314 242.43 318.403 248.82 328.427 256.52C332.198 259.416 334.472 257.69 334.472 257.69L337.875 249.702L314.117 218.741Z'
          fill='url(#paint7_linear_1_8357)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M314.117 218.741L304.462 219.329C304.462 219.329 299.598 221.333 304.537 230.227C311.314 242.43 318.403 248.82 328.427 256.52C332.198 259.416 334.472 257.69 334.472 257.69L337.875 249.702L314.117 218.741Z'
          fill='url(#paint8_linear_1_8357)'
        />
        <ellipse
          cx='19.5903'
          cy='6.47801'
          rx='19.5903'
          ry='6.47801'
          transform='matrix(-0.601815 -0.798635 -0.798635 0.601815 342.448 246.516)'
          fill='#47474D'
        />
        <ellipse
          cx='14.6933'
          cy='4.85829'
          rx='14.6933'
          ry='4.85829'
          transform='matrix(-0.587785 -0.809017 -0.809017 0.587785 339.555 245.763)'
          fill='#323337'
        />
        <mask
          id='mask2_1_8357'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x='317'
          y='224'
          width='20'
          height='25'
        >
          <ellipse
            cx='14.6933'
            cy='4.85829'
            rx='14.6933'
            ry='4.85829'
            transform='matrix(-0.587785 -0.809017 -0.809017 0.587785 339.555 245.763)'
            fill='white'
          />
        </mask>
        <g mask='url(#mask2_1_8357)'>
          <ellipse
            cx='11.9718'
            cy='4.31867'
            rx='11.9718'
            ry='4.31867'
            transform='matrix(-0.573577 -0.819152 -0.819152 0.573577 337.216 248.03)'
            fill='url(#paint9_linear_1_8357)'
          />
        </g>
        <ellipse
          cx='262.434'
          cy='231.616'
          rx='19.733'
          ry='9.77565'
          transform='rotate(39 262.434 231.616)'
          fill='#3B3B40'
        />
        <mask
          id='mask3_1_8357'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x='245'
          y='217'
          width='34'
          height='30'
        >
          <ellipse
            cx='262.434'
            cy='231.616'
            rx='19.733'
            ry='9.77565'
            transform='rotate(39 262.434 231.616)'
            fill='white'
          />
        </mask>
        <g mask='url(#mask3_1_8357)'>
          <ellipse
            cx='264.603'
            cy='231.502'
            rx='19.733'
            ry='9.77565'
            transform='rotate(39 264.603 231.502)'
            fill='url(#paint10_linear_1_8357)'
          />
        </g>
      </g>
      <g opacity='0.738118' filter='url(#filter3_i_1_8357)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M324.86 185.426C326.647 185.239 328.248 186.531 328.435 188.312C328.622 190.094 327.326 191.69 325.539 191.878C304.046 194.137 284.798 178.599 282.546 157.172C282.358 155.391 283.655 153.794 285.442 153.607C287.229 153.419 288.83 154.711 289.017 156.492C290.894 174.355 306.942 187.31 324.86 185.426ZM323.294 170.525C325.081 170.337 326.682 171.629 326.869 173.411C327.056 175.192 325.759 176.789 323.972 176.977C310.735 178.368 298.88 168.798 297.493 155.601C297.305 153.82 298.602 152.223 300.389 152.036C302.176 151.848 303.777 153.14 303.964 154.921C304.977 164.554 313.631 171.54 323.294 170.525ZM324.352 154.669C324.049 151.784 321.456 149.69 318.562 149.995C315.667 150.299 313.567 152.886 313.87 155.77C314.173 158.656 316.766 160.75 319.66 160.445C322.555 160.141 324.655 157.555 324.352 154.669Z'
          fill='url(#paint11_radial_1_8357)'
        />
      </g>
      <rect
        x='348.281'
        y='62.9211'
        width='77.0924'
        height='11.7467'
        transform='rotate(40 348.281 62.9211)'
        fill='#3F3F45'
      />
      <path
        d='M376.073 50.605C384.731 40.2877 400.112 38.942 410.43 47.5992C420.747 56.2564 422.093 71.6383 413.435 81.9555L371.223 132.263L333.861 100.912L376.073 50.605Z'
        fill='url(#paint12_radial_1_8357)'
      />
      <g filter='url(#filter4_d_1_8357)'>
        <rect
          x='414.506'
          y='86.7992'
          width='34.6129'
          height='62.6489'
          rx='1.89588'
          transform='rotate(40 414.506 86.7992)'
          fill='url(#paint13_linear_1_8357)'
        />
      </g>
      <rect
        opacity='0.296642'
        x='414.796'
        y='96.2435'
        width='22.8131'
        height='20.3609'
        rx='0.631961'
        transform='rotate(40 414.796 96.2435)'
        fill='url(#paint14_linear_1_8357)'
      />
      <rect
        opacity='0.296642'
        x='397.682'
        y='116.64'
        width='22.8131'
        height='20.3609'
        rx='0.631961'
        transform='rotate(40 397.682 116.64)'
        fill='url(#paint15_linear_1_8357)'
      />
      <g filter='url(#filter5_d_1_8357)'>
        <rect
          x='344.602'
          y='28.1433'
          width='34.6129'
          height='62.6489'
          rx='1.89588'
          transform='rotate(40 344.602 28.1433)'
          fill='url(#paint16_linear_1_8357)'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M333.861 100.912L371.223 132.263L352.917 139.393L330.018 120.178L333.861 100.912Z'
        fill='url(#paint17_linear_1_8357)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M349.6 168.736C350.142 168.179 350.668 167.598 351.176 166.993C362.297 153.74 360.519 133.941 347.207 122.771C333.894 111.6 314.088 113.288 302.967 126.54C302.459 127.146 301.978 127.765 301.524 128.396L349.6 168.736Z'
        fill='url(#paint18_radial_1_8357)'
      />
      <mask
        id='mask4_1_8357'
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x='301'
        y='115'
        width='58'
        height='54'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M349.6 168.736C350.142 168.179 350.668 167.598 351.176 166.993C362.297 153.74 360.519 133.941 347.207 122.771C333.894 111.6 314.088 113.288 302.967 126.54C302.459 127.146 301.978 127.765 301.524 128.396L349.6 168.736Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_1_8357)'></g>
      <rect
        opacity='0.296642'
        x='344.893'
        y='37.5876'
        width='22.8131'
        height='20.3609'
        rx='0.631961'
        transform='rotate(40 344.893 37.5876)'
        fill='url(#paint19_linear_1_8357)'
      />
      <rect
        opacity='0.296642'
        x='327.778'
        y='57.9842'
        width='22.8131'
        height='20.3609'
        rx='0.631961'
        transform='rotate(40 327.778 57.9842)'
        fill='url(#paint20_linear_1_8357)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M267.531 60.9387L262.771 67.3887L260.504 59.6998L253.384 55.114L261.643 53.2398L266.392 46.8508L268.659 54.5397L275.779 59.1255L267.531 60.9387Z'
        fill='#323337'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M440.166 183.768L436.7 188.679L435.124 182.879L429.981 179.473L435.979 178.031L439.444 173.12L441.02 178.92L446.163 182.326L440.166 183.768Z'
        fill='#323337'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M393.656 230.639L391.798 233.183L390.923 230.157L388.157 228.358L391.372 227.613L393.225 225.093L394.1 228.118L396.866 229.918L393.656 230.639Z'
        fill='#323337'
      />
      <defs>
        <filter
          id='filter0_i_1_8357'
          x='-12.9374'
          y='126.279'
          width='341.557'
          height='328.906'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-6' dy='6' />
          <feGaussianBlur stdDeviation='10.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.27451 0 0 0 0 0.282353 0 0 0 0 0.301961 0 0 0 0.8 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_1_8357' />
        </filter>
        <filter
          id='filter1_f_1_8357'
          x='155.128'
          y='128.204'
          width='50.5169'
          height='39.2405'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.47628' result='effect1_foregroundBlur_1_8357' />
        </filter>
        <filter
          id='filter2_f_1_8357'
          x='291.667'
          y='208.137'
          width='29.6672'
          height='37.6964'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.47628' result='effect1_foregroundBlur_1_8357' />
        </filter>
        <filter
          id='filter3_i_1_8357'
          x='276.528'
          y='149.965'
          width='51.9255'
          height='48.1309'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-6' dy='6' />
          <feGaussianBlur stdDeviation='10.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.27451 0 0 0 0 0.282353 0 0 0 0 0.301961 0 0 0 0.8 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_1_8357' />
        </filter>
        <filter
          id='filter4_d_1_8357'
          x='373.011'
          y='86.5742'
          width='71.2349'
          height='74.6905'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0392157 0 0 0 0 0.0392157 0 0 0 0.4 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_8357' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_8357'
            result='shape'
          />
        </filter>
        <filter
          id='filter5_d_1_8357'
          x='303.108'
          y='27.9183'
          width='71.2349'
          height='74.6905'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0392157 0 0 0 0 0.0392157 0 0 0 0.4 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_8357' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_8357'
            result='shape'
          />
        </filter>
        <radialGradient
          id='paint0_radial_1_8357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(298.696 172.024) rotate(90) scale(147.742 166.303)'
        >
          <stop stopColor='white' />
          <stop offset='0.37727' stopColor='#949494' />
          <stop offset='0.643924' stopColor='#656565' stopOpacity='0.5' />
          <stop offset='0.979255' stopColor='white' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_1_8357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(152.557 164.629) rotate(90) scale(275.867 503.889)'
        >
          <stop stopColor='#2A2B2E' />
          <stop offset='1' stopColor='#212224' />
        </radialGradient>
        <linearGradient
          id='paint2_linear_1_8357'
          x1='64.4705'
          y1='5.27295'
          x2='26.6206'
          y2='5.27295'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0B0B0D' stopOpacity='0.1' />
          <stop offset='1' stopColor='#080A0D' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1_8357'
          x1='211.244'
          y1='175.223'
          x2='195.625'
          y2='137.276'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3D3F42' />
          <stop offset='1' stopColor='#232426' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1_8357'
          x1='191.074'
          y1='164.879'
          x2='194.53'
          y2='157.793'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1C1D1F' stopOpacity='0.8' />
          <stop offset='1' stopColor='#1C1D1F' stopOpacity='0.01' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1_8357'
          x1='27.9292'
          y1='-8.23125'
          x2='-30.1705'
          y2='11.4295'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#232426' />
          <stop offset='1' stopColor='#47494D' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_1_8357'
          x1='50.6818'
          y1='6.12836'
          x2='43.9489'
          y2='-5.87053'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#17181B' stopOpacity='0.1' />
          <stop offset='1' stopColor='#0F131A' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_1_8357'
          x1='320.47'
          y1='252.862'
          x2='319.222'
          y2='223.619'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3D3F42' />
          <stop offset='1' stopColor='#232426' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_1_8357'
          x1='311.035'
          y1='241.305'
          x2='314.426'
          y2='238.03'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1C1D1F' stopOpacity='0.8' />
          <stop offset='1' stopColor='#1C1D1F' stopOpacity='0.01' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_1_8357'
          x1='22.6691'
          y1='-8.95171'
          x2='-39.8311'
          y2='-8.95171'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#232426' />
          <stop offset='1' stopColor='#47494D' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_1_8357'
          x1='288.424'
          y1='206.407'
          x2='205.53'
          y2='222.27'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#232426' />
          <stop offset='1' stopColor='#47494D' />
        </linearGradient>
        <radialGradient
          id='paint11_radial_1_8357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(318.921 156.905) rotate(129.134) scale(34.9672 55.5305)'
        >
          <stop stopColor='#46484D' />
          <stop offset='1' stopColor='#17181A' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_1_8357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(386.059 54.8342) rotate(98.5727) scale(62.8734 56.3561)'
        >
          <stop stopColor='#72757A' />
          <stop offset='1' stopColor='#4C4E52' />
        </radialGradient>
        <linearGradient
          id='paint13_linear_1_8357'
          x1='417.11'
          y1='74.5097'
          x2='417.11'
          y2='127.732'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#515356' />
          <stop offset='1' stopColor='#3C3D40' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_1_8357'
          x1='405.884'
          y1='87.9332'
          x2='405.884'
          y2='124.203'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5E6064' stopOpacity='0.5' />
          <stop offset='0.999023' stopColor='#6F737A' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_1_8357'
          x1='384.738'
          y1='100.775'
          x2='384.738'
          y2='144.24'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5E6064' stopOpacity='0.3' />
          <stop offset='0.999023' stopColor='#6F737A' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_1_8357'
          x1='347.206'
          y1='15.8538'
          x2='347.206'
          y2='69.0765'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#515356' />
          <stop offset='1' stopColor='#3C3D40' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_1_8357'
          x1='326.663'
          y1='94.8724'
          x2='310.219'
          y2='114.469'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#47494D' />
          <stop offset='1' stopColor='#4C4E52' />
        </linearGradient>
        <radialGradient
          id='paint18_radial_1_8357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(328.253 117.218) rotate(65.3718) scale(46.8165 36.5155)'
        >
          <stop stopColor='#606166' />
          <stop offset='1' stopColor='#48494D' />
        </radialGradient>
        <linearGradient
          id='paint19_linear_1_8357'
          x1='335.98'
          y1='29.2774'
          x2='335.98'
          y2='65.5475'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5E6064' stopOpacity='0.5' />
          <stop offset='0.999023' stopColor='#6F737A' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_1_8357'
          x1='314.835'
          y1='42.1193'
          x2='314.835'
          y2='85.584'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5E6064' stopOpacity='0.3' />
          <stop offset='0.999023' stopColor='#6F737A' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default Error404;
