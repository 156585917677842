import moment from 'moment';
import 'moment-duration-format';

const formatToYear = (date: string) => moment(date).format('YYYY');
const formatToYearMonthDay = (date: Date) => moment(date).format('YYYY-MM-DD');

const minutesToHours = (runtime: number): string | undefined => {
  const t = moment.duration(runtime, 'minutes').format('h[h] m[min]');
  return `${t}`;
};

const millisecondsToHours = (duration: string): string => {
  const t = moment.duration(duration, 'seconds').format('h[h] m[min]');
  return `${t}`;
};

const numberToAmPm = (h: number | string): string => {
  return moment(`${h}`, 'hh').format('hh:mm a');
};

const hoursAndMinutesToAm = (time: string): string => {
  return moment(`${time}`, 'hh:mm').format('hh:mm a');
};

const toUnixTime = (hour: string | number): string => {
  const h = moment(hour, 'HH:mm:ss').unix();
  return h.toString();
};

const isTimeBetween = (start: string, end: string) => {
  const currentTime = moment();

  const startTime = moment(start, 'HH:mm:ss');
  const endTime = moment(end, 'HH:mm:ss');

  return currentTime.isBetween(startTime, endTime);
};

export {
  formatToYear,
  formatToYearMonthDay,
  millisecondsToHours,
  minutesToHours,
  toUnixTime,
  numberToAmPm,
  hoursAndMinutesToAm,
  isTimeBetween,
};
