const AF_RESOURCE = {
  translation: {
    loading: 'Laai tans',
    search: {
      cancel: 'Kanselleer',
      noResults:
        'Geen soekresultate gevind nie, maar die onderstaande is beslis die moeite werd om na te gaan.',
    },
    menu: {
      switchProfiles: 'Verander profiel',
      settings: 'Instellings',
      close: 'Maak toe',
      logOut: 'Teken uit',
    },
    button: {
      apply: 'Pas toe',
      continue: 'Gaan voort',
      cancel: 'Kanselleer',
      confirm: 'Bevestig',
      play: 'Speel',
      addToWatchList: 'Voeg by tot kyklys',
      removeFromWatchList: 'Verwyder uit kyklys',
      moreInfo: 'Meer inligting',
      resume: 'Hervat',
      close: 'Maak toe',
    },
    login: {
      header: 'Rekeningaanmelding',
      title: 'Welkom by',
      subTitle: 'Kry die meeste uit jou stroomdiensintekeninge met Disney, Amazon en meer.',
      text: 'Alles wat jy wil kyk op een plek.',
      button: 'Meld aan',
      termsOfUse: 'Gebruiksvoorwaardes',
      placeholder: {
        username: 'Gebruikersnaam',
        password: 'Wagwoord',
      },
      errors: {
        email: {
          noValid: 'E-posadres moet ’n geldige e-posadres wees',
          required: 'E-posadres is ’n verpligte veld',
        },
        password: {
          required: 'Wagwoord is ’n verpligte veld',
        },
        wrongPass: {
          first: 'Oeps, dit was nie die regte wagwoord nie. Probeer asseblief weer of klik op ',
          second: '“Wagwoord vergeet” ',
          third: 'knoppie hier onder.',
        },
      },
    },
    forgotPassword: {
      message:
        'Moenie bekommerd wees nie. Dit is maklik om jou wagwoord terug te stel. Voer net jou e-posadres hier onder in en klik op "STUUR"',
      button: 'STUUR',
      placeholder: {
        username: 'Gebruikersnaam',
      },
      errors: {
        email: {
          required: 'E-posadres is ’n verpligte veld',
        },
        emailNotRegister: 'E-posadres is nie geregistreer nie!',
        emailSent: 'E-pos vir terugstelling van wagwoord is gestuur!',
      },
    },
    profilesPage: {
      title: 'Wie kyk?',
      subTitle:
        "Almal in jou motor kan 'n gepersonaliseerde TV-ervaring hê. Voeg 'n nuwe profiel by.",
      newProfile: 'Nuwe profiel',
    },
    newProfile: {
      isKid: 'Kinders?',
      loading: 'Laai tans',
      profileCreated: 'Profiel suksesvol geskep!',
      profileSelection: 'Profielkeuse',
    },
    settings: {
      accountDetails: {
        primary: 'Rekeningbesonderhede',
        secondary: 'Wysig naam van rekeninghouer en e-posadres.',
      },
      manageProfiles: {
        primary: 'Bestuur profiele',
        secondary: 'Wysig besonderhede',
      },
      parentControl: {
        primary: 'Ouertoesig',
        secondary: 'Skep PIN / Verander PIN',
        instruction: '(Voer asseblief 4 syfers in terwyl die sleutelbord oop is)',
        recoverPin: 'Herstel PIN',
        pinSentTo: 'PIN gestuur na',
      },
      membershipStatus: {
        primary: 'Lidmaatskapstatus',
        secondary: 'Aktief',
      },
      deactivateAccount: {
        primary: 'Deaktiveer rekening',
        secondary: 'Maak jou rekening toe en ontkoppel jou toestelle.',
        enterPass: 'Sleutel jou wagwoord in',
      },
      activeLanguage: 'Aktiewe taal',
      defaultLanguage: 'Stelseltaal',
      privacyPolicy: {
        text: 'Privaatheidsbeleid',
      },
      settings: 'Instellings',
      lang: {
        en: 'Engels',
        it: 'Italiaans',
        de: 'Duits',
        esar: 'Spaans(Argentinië)',
        fr: 'Frans',
        ja: 'Japannees',
        ro: 'Roemeens',
        da: 'Deens',
        nl: 'Nederlands',
        ca: 'Katalaans',
        lb: 'Luxemburgs',
        no: 'Noors',
        ptbr: 'Portugees(Brasilië)',
        zhcn: 'Chinees',
        ms: 'Maleisies',
        ta: 'Tamil',
        bs: 'Bosnies',
        bg: 'Bulgaars',
        hr: 'Kroaties',
        tr: 'Turks',
        cs: 'Tsjeggies',
        et: 'Estlands',
        fi: 'Fins',
        el: 'Grieks',
        hu: 'Hongaars',
        ga: 'Iers',
        is: 'Yslands',
        lv: 'Letties',
        lt: 'Litaus',
        mt: 'Maltees',
        pl: 'Pools',
        pt: 'Portugees (Portugal)',
        ru: 'Russies',
        sr: 'Serwies',
        sk: 'Slowaaks',
        sl: 'Sloweens',
        sv: 'Sweeds',
        mi: 'Māori',
        ar: 'Arabies',
        af: 'Afrikaans',
        zhtw: 'Chinees(Tradisionele)',
        es: 'Spaans(Spanje)',
        ko: 'Koreaan',
      },
      language: {
        primary: 'Taal',
      },
    },
    show: {
      more: ' Wys meer',
      less: ' Wys minder',
    },
    showPage: {
      seasons: 'Seisoene',
      cast: 'Rolverdeling',
      producers: 'Vervaardigers',
      genre: 'Genre',
      directedBy: 'Regie deur',
      season: 'Seisoen',
    },
    accountDetails: {
      firstName: 'Voornaam',
      lastName: 'Van',
      email: 'E-posadres',
      password: 'Wagwoord',
      changePassword: 'Verander wagwoord',
      forgotPassword: 'Wagwoord vergeet',
    },
    childPinPopup: {
      title: 'Kinderbeskerming',
      subTitle: 'Skep (opdateer) PIN vir kinderbeskerming',
      pinUpdated: 'PIN is suksesvol opgedateer',
      pinIsIncorrect: 'PIN is verkeerd',
    },
    deactivateAccount: {
      title: 'Is jy seker jy wil jou rekening deaktiveer?',
      description:
        'Sodra jy dit doen, sal jy by alle dienste afgemeld word en sal jy nie meer inhoud in jou motor kan sien nie',
      deactivateButton: 'Deaktiveer rekening',
    },
    servicesPage: {
      add: 'Voeg by dienste',
      remove: 'Verwyder uit dienste',
      overflow: {
        title: 'Geselekteerde pakke',
        subtitle: 'Kies jou dienste',
        description: 'Voeg elke diens wat jy gebruik by jou gids',
      },
    },
    manageProfile: {
      text: 'Bestuur profiele',
    },
    watchlist: {
      title: 'Hier is al die flieks en programme wat jy by jou kyklys gevoeg het',
      noTitles: 'Jy het tans geen titels in jou kyklys nie',
      addText:
        'Om by te voeg, klik asseblief op + Voeg by tot kyklys op enige program waarna jy graag wil terugkeer en op ’n later datum wil kyk.',
      subtext: {
        start: 'Om by te voeg, klik asseblief op ',
        toWatchList: 'Voeg by tot kyklys',
        end: 'vir enige program waarna jy graag wil terugkeer en op ’n later datum wil kyk.',
      },
    },
    streamingService: {
      title: 'Kies stroomdienste',
      subTitle: 'Voeg jou geselekteerde dienste by om in jou TV-gids te verskyn',
      streamingServices: 'Stroomdienste',
    },
    profileUpdate: {
      backToSettings: 'Terug na Instellings',
      updatedSuccess: 'Gebruiker is suksesvol opgedateer',
      apply: 'Pas toe',
      wrongPassword: 'Verkeerde wagwoord',
    },
    changePasswordPage: {
      backToSettings: 'Terug na Instellings',
      currentPass: 'Huidige wagwoord',
      newPass: 'Nuwe wagwoord',
      confirmPass: 'Bevestig wagwoord',
      change: 'Verander',
      changeUserInfo: 'Verander gebruikerinligting',
      passwordDoNotMatch: 'Wagwoorde stem nie ooreen nie of is ongeldig',
      passwordUpdated: 'Wagwoord is suksesvol opgedateer',
    },
    noServices: {
      title: 'Jy het tans geen dienste in jou TV-gids nie',
      text: 'Om die meeste uit ScreenHits TV te kry, moet jy dienste byvoeg. Klik asseblief hier om die stroomdienste gratis by te voeg waarop jy reeds ingeteken is. En as jy nie tans op enige stroomdiens inteken nie, voeg ons GRATIS bondel hier by en begin wonderlike inhoud van BBC, ITV, My5, Channel 4, UKTV en STV ontdek.',
      button: 'Voeg dienste by',
    },
    deleteProfile: {
      cantDeleteProfile: 'Jy kan nie jou huidige profiel skrap nie',
      areYouSure: 'Is jy seker jy wil hierdie profiel skrap?',
      delete: 'Skrap',
    },
    notFoundPage: {
      title: 'Iets het verkeerd geloop',
      text: 'Moenie bekommerd wees nie, alles is reg. Ons sal terugkom met hierdie bladsy.',
      home: 'Tuisblad',
    },
  },
};

export default AF_RESOURCE;
