import axios, { AxiosInstance } from 'axios';

function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
}

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Device-Type': process.env.REACT_APP_DEVICE_TYPE as string,
  },
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // If the response is successful (status code 2xx), simply return the response.
    return response;
  },
  async (error) => {
    // Check if the error is a 401
    if (error.response && (error.response.status === 401 || error.response.status === 404)) {
      console.log('401 Unauthorized error occurred. Redirecting to login...');

      if (error.response?.config?.headers?.Authorization) {
        window.localStorage.clear();
        clearAllCookies();
        window.location.href = '/';
        return;
      }

      return Promise.reject(error);
    }

    // If it's a different error (e.g., 403, 500), return the error as usual
    return Promise.reject(error);
  },
);

const setAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setApiLanguageHeader = (language: string): void => {
  apiClient.defaults.headers.common['language'] = language;
};

export { apiClient, setAuthorizationHeader, setApiLanguageHeader };
