import { Backgrounds, BackgroundsPromise } from 'types/Backgrounds';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';
import axios from 'axios';

const endpoint = ENDPOINTS.MISC;

const miscApiUrl = {
  getBackgrounds: `${endpoint}/backgrounds`,
  checkConnection: `${process.env.REACT_APP_API_BASE_URL}misc/internet`,
};

const getBackgrounds = (): BackgroundsPromise =>
  apiClient.get<Backgrounds>(miscApiUrl.getBackgrounds).then((res) => res.data);

const checkConnection = () =>
  axios
    .get(miscApiUrl.checkConnection, {
      timeout: 10000,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((res) => res.data);

export { miscApiUrl, getBackgrounds, checkConnection };
