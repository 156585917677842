const DA_RESOURCE = {
  translation: {
    loading: 'Indlæser',
    search: {
      cancel: 'Annuller',
      noResults: 'Ingen søgeresultater fundet, men nedenstående er bestemt værd at tjekke ud.',
    },
    menu: {
      switchProfiles: 'Skift profil',
      settings: 'Indstillinger',
      close: 'Luk',
      logOut: 'Log ud',
    },
    button: {
      apply: 'Anvend',
      continue: 'Fortsæt',
      cancel: 'Annuller',
      confirm: 'Bekræft',
      play: 'Afspil',
      addToWatchList: 'Tilføj til Watchlist',
      removeFromWatchList: 'Fjern fra Watchlist',
      moreInfo: 'Mere info',
      resume: 'Genoptage',
      close: 'Luk',
    },
    login: {
      header: 'Konto-login',
      title: 'Velkommen til',
      subTitle: 'Få mest muligt ud af dine streamingabonnementer hos Disney, Amazon og meget mere.',
      text: 'Alt, hvad du ønsker at se, samlet på ét sted.',
      button: 'Log ind',
      termsOfUse: 'Vilkår for brug',
      placeholder: {
        username: 'Brugernavn',
        password: 'Adgangskode',
      },
      errors: {
        email: {
          noValid: 'E-mail skal være en gyldig e-mailadresse',
          required: 'E-mail er et obligatorisk felt',
        },
        password: {
          required: 'Adgangskode er et obligatorisk felt',
        },
        wrongPass: {
          first:
            'Ups, det var ikke den rigtige adgangskode. Prøv venligst igen eller klik på knappen',
          second: '"Glemt adgangskode" ',
          third: 'nedenfor.',
        },
      },
    },
    forgotPassword: {
      message:
        'Du skal ikke være bekymret. Det er nemt at nulstille din adgangskode. Du skal blot indtaste din e-mailadresse nedenfor og klikke på "SEND"',
      button: 'SEND',
      placeholder: {
        username: 'Brugernavn',
      },
      errors: {
        email: {
          required: 'E-mail er et obligatorisk felt',
        },
        emailNotRegister: 'E-mail-adresse ikke registreret!',
        emailSent: 'E-mail med nulstilling af adgangskode sendt!',
      },
    },
    profilesPage: {
      title: 'Vælg profil',
      subTitle: 'Alle i din bil kan få en personlig TV-oplevelse. Tilføj en ny profil.',
      newProfile: 'Ny profil',
    },
    newProfile: {
      isKid: 'Børn?',
      loading: 'Indlæser',
      profileCreated: 'Profilen er oprettet!',
      profileSelection: 'Valg af profil',
    },
    settings: {
      accountDetails: {
        primary: 'Kontooplysninger',
        secondary: 'Rediger navn på kontoindehaver og e-mail.',
      },
      manageProfiles: {
        primary: 'Administrer profiler',
        secondary: 'Rediger detaljer',
      },
      parentControl: {
        primary: 'Forældrekontrol',
        secondary: 'Opret pinkode / ændre pinkode',
        instruction: '(Indtast venligst 4 cifre, mens tastaturet er åbent)',
        recoverPin: 'Gendan pinkode',
        pinSentTo: 'Pinkode sendt til',
      },
      membershipStatus: {
        primary: 'Status for medlemskab',
        secondary: 'Aktivt',
      },
      deactivateAccount: {
        primary: 'Deaktiver konto',
        secondary: 'Luk din konto, og fjern linket til dine enheder.',
        enterPass: 'Indtast din adgangskode',
      },
      activeLanguage: 'Aktivt sprog',
      defaultLanguage: 'Systemsprog',
      privacyPolicy: {
        text: 'Privatlivspolitik',
      },
      settings: 'Indstillinger',
      lang: {
        en: 'Engelsk',
        it: 'Italiensk',
        de: 'Tysk',
        esar: 'Spansk(Argentina)',
        fr: 'Fransk',
        ja: 'Japansk',
        ro: 'Rumænsk',
        da: 'Dansk',
        nl: 'Hollandsk',
        ca: 'Catalansk',
        lb: 'Louxembourgsk',
        no: 'Norsk',
        ptbr: 'Portugisisk(BR)',
        zhcn: 'Kinesisk',
        ms: 'Malaysisk',
        ta: 'Tamilsk',
        bs: 'Bosnisk',
        bg: 'Bulgarsk',
        hr: 'Kroatisk',
        tr: 'Tyrkisk',
        cs: 'Tjekkisk',
        et: 'Estisk',
        fi: 'Finsk',
        el: 'Græsk',
        hu: 'Ungarsk',
        ga: 'Irsk',
        is: 'Islandsk',
        lv: 'Lettisk',
        lt: 'Litauisk',
        mt: 'Maltesisk',
        pl: 'Polsk',
        pt: 'Portugisisk(PT)',
        ru: 'Russisk',
        sr: 'Serbisk',
        sk: 'Slovakisk',
        sl: 'Slovensk',
        sv: 'Svensk',
        mi: 'Māori',
        ar: 'Arabisk',
        af: 'Afrikaans',
        zhtw: 'Kinesisk (Traditionelt)',
        es: 'Spansk(Spanien)',
        ko: 'Koreansk',
      },
      language: {
        primary: 'Sprog',
      },
    },
    show: {
      more: ' Vis mere',
      less: ' Vis mindre',
    },
    showPage: {
      seasons: 'Sæsoner',
      cast: 'Skuespillere',
      producers: 'Producenter',
      genre: 'Genre',
      directedBy: 'Instrueret af',
      season: 'Sæson',
    },
    accountDetails: {
      firstName: 'Fornavn',
      lastName: 'Efternavn',
      email: 'E-mail',
      password: 'Adgangskode',
      changePassword: 'Ændre adgangskode',
      forgotPassword: 'Glemt adgangskode',
    },
    childPinPopup: {
      title: 'Børnebeskyttelse',
      subTitle: 'Opret (opdater) pinkode til børnebeskyttelse',
      pinUpdated: 'Pinkoden er blevet opdateret',
      pinIsIncorrect: 'Pinkoden er forkert',
    },
    deactivateAccount: {
      title: 'Er du sikker på, at du ønsker at deaktivere din konto?',
      description:
        'Når du gør det, vil du blive logget ud af alle tjenester, og du vil ikke længere kunne se indholdet i din bil.',
      deactivateButton: 'Deaktiver konto',
    },
    servicesPage: {
      add: 'Tilføj til tjenester',
      remove: 'Fjern fra tjenester',
      overflow: {
        title: 'Valgte pakker',
        subtitle: 'Vælg dine tjenester',
        description: 'Tilføj alle de tjenester, du bruger, til din guide',
      },
    },
    manageProfile: {
      text: 'Administrer profiler',
    },
    watchlist: {
      title: 'Her er alle de film og serier, du har tilføjet til din Watchlist',
      noTitles: 'Du har ingen titler på din Watchlist i øjeblikket',
      addText:
        'Hvis du vil tilføje et program, skal du klikke på + Tilføj til Watchlist på et program, som du gerne vil vende tilbage til og se på et senere tidspunkt.',
      subtext: {
        start: 'Tilføj ved at klikke på  ',
        toWatchList: 'Tilføj til Watchlist',
        end: 'for ethvert program, som du gerne vil vende tilbage til og se på et senere tidspunkt.',
      },
    },
    streamingService: {
      title: 'Vælg streamingtjenester',
      subTitle: 'Tilføj dine valgte tjenester til at blive vist i din TV-Guide',
      streamingServices: 'Streamingtjenester',
    },
    profileUpdate: {
      backToSettings: 'Tilbage til indstillinger',
      updatedSuccess: 'Brugeren blev opdateret',
      apply: 'Anvend',
      wrongPassword: 'Forkert adgangskode',
    },
    changePasswordPage: {
      backToSettings: 'Tilbage til indstillinger',
      currentPass: 'Nuværende adgangskode',
      newPass: 'Ny adgangskode',
      confirmPass: 'Bekræft adgangskode',
      change: 'Ændre',
      changeUserInfo: 'Ændre brugeroplysninger',
      passwordDoNotMatch: 'Adgangskoderne er ikke ens, eller er ugyldige',
      passwordUpdated: 'Adgangskode opdateret',
    },
    noServices: {
      title: 'Du har i øjeblikket ingen tjenester i din TV-Guide',
      text: 'For at få mest muligt ud af ScreenHits TV skal du tilføje tjenester. Klik venligst her for at tilføje de tjenester, du allerede abonnerer på, uden ekstra omkostninger. Og hvis du i øjeblikket ikke abonnerer på nogen streamingtjenester, kan du tilføje vores GRATIS pakke og begynde at opdage fantastisk indhold.',
      button: 'Tilføj tjenester',
    },
    deleteProfile: {
      cantDeleteProfile: 'Du kan ikke slette din nuværende profil',
      areYouSure: 'Er du sikker på, at du vil slette denne profil?',
      delete: 'Slet',
    },
    notFoundPage: {
      title: 'Noget gik galt',
      text: 'Bare rolig, alt er ok. Vi vender tilbage med denne side.',
      home: 'Startside',
    },
  },
};

export default DA_RESOURCE;
