import { Dispatch } from 'react';
import { UserData } from 'types/Auth';
import { Backgrounds } from 'types/Backgrounds';
import { ChannelMediaData } from 'types/Channels';
import { GenreMediaData, GenreParams } from 'types/Genres';
import { MediaData, WatchList } from 'types/Media';
import { OtherGenreFilterEnum } from 'types/OtherGenre';
import { ProfileData } from 'types/Profile';

export interface AppStateContext {
  userData: UserData;
  isAuth: boolean;
  watchList: WatchList | undefined;
  childProtection: boolean;
  genreParams: GenreParams;
  genreMedia: Array<GenreMediaData>;
  selectedMedia: MediaData | ChannelMediaData;
  selectedProfile: ProfileData;
  redirectURL: string;
  backgrounds: Backgrounds;
  redirectedFrom: string | undefined;
  prevUrl: string;
  otherGenreFilter: OtherGenreFilterEnum;
  selectedGenreId: number;
  location: string;
  searchQuery: string;
  channelId: string | undefined;
  isPWDEntered: string | null;
}

export interface IAppContext {
  state: AppStateContext;
  dispatch: Dispatch<AppActions>;
}

export enum AppActionsEnums {
  SET_USER_DATA = 'SET_USER_DATA',
  SET_IS_AUTH = 'SET_IS_AUTH',
  SET_CHILD_PROTECTION = 'SET_CHILD_PROTECTION',
  SET_WATCH_LIST = 'SET_WATCH_LIST',
  SET_GENRE_PARAMS = 'SET_GENRE_PARAMS',
  SET_GENRE_MEDIA = 'SET_GENRE_MEDIA',
  SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA',
  SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE',
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  SET_BACKGROUNDS = 'SET_BACKGROUNDS',
  SET_REDIRECTED_FROM = 'SET_REDIRECTED_FROM',
  SET_PREV_URL = 'SET_PREV_URL',
  SET_OTHER_GENRE_FILTER = 'SET_OTHER_GENRE_FILTER',
  SET_SELECTED_GENRE_ID = 'SET_SELECTED_GENRE_ID',
  SET_LOCATION = 'SET_LOCATION',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  SET_CHANNEL_ID = 'SET_CHANNEL_ID',
  SET_IS_PWD_ENTERED = 'SET_IS_PWD_ENTERED',
}

interface setUserData {
  type: AppActionsEnums.SET_USER_DATA;
  payload: UserData;
}

interface setIsAuth {
  type: AppActionsEnums.SET_IS_AUTH;
  payload: boolean;
}

interface setChildProtection {
  type: AppActionsEnums.SET_CHILD_PROTECTION;
  payload: boolean;
}

interface setWatchList {
  type: AppActionsEnums.SET_WATCH_LIST;
  payload: WatchList | undefined;
}

interface setGenreParams {
  type: AppActionsEnums.SET_GENRE_PARAMS;
  payload: { key: string; value: string | number };
}

interface setGenreMedia {
  type: AppActionsEnums.SET_GENRE_MEDIA;
  payload: Array<GenreMediaData>;
}

interface setSelectedMedia {
  type: AppActionsEnums.SET_SELECTED_MEDIA;
  payload: MediaData | ChannelMediaData;
}

interface setSelectedProfile {
  type: AppActionsEnums.SET_SELECTED_PROFILE;
  payload: ProfileData;
}

interface setRedirectUrl {
  type: AppActionsEnums.SET_REDIRECT_URL;
  payload: string;
}

interface setBackgrounds {
  type: AppActionsEnums.SET_BACKGROUNDS;
  payload: Backgrounds;
}

interface SetRedirectedFrom {
  type: AppActionsEnums.SET_REDIRECTED_FROM;
  payload: string | undefined;
}

interface SetPrevUrl {
  type: AppActionsEnums.SET_PREV_URL;
  payload: string;
}

interface SetOtherGenreFilter {
  type: AppActionsEnums.SET_OTHER_GENRE_FILTER;
  payload: OtherGenreFilterEnum;
}

interface SetSelectedGenreId {
  type: AppActionsEnums.SET_SELECTED_GENRE_ID;
  payload: number;
}

interface SetLocation {
  type: AppActionsEnums.SET_LOCATION;
  payload: string;
}

interface SetSearchQuery {
  type: AppActionsEnums.SET_SEARCH_QUERY;
  payload: string;
}

interface SetChannelId {
  type: AppActionsEnums.SET_CHANNEL_ID;
  payload: string;
}

interface SetIsPasswordEntered {
  type: AppActionsEnums.SET_IS_PWD_ENTERED;
  payload: string;
}

export type AppActions =
  | setUserData
  | setIsAuth
  | setChildProtection
  | setWatchList
  | setGenreParams
  | setGenreMedia
  | setSelectedMedia
  | setSelectedProfile
  | setRedirectUrl
  | setBackgrounds
  | SetRedirectedFrom
  | SetPrevUrl
  | SetOtherGenreFilter
  | SetSelectedGenreId
  | SetLocation
  | SetSearchQuery
  | SetChannelId
  | SetIsPasswordEntered;
