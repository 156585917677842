import { MediaLiteData, MediaLinkData } from './../types/Media';
import { NavigateFunction } from 'react-router-dom';
import { toUnixTime } from './DataTime';
import { appLinks } from 'routes/routes';
import { getLocationFromLocalStorage } from './localStorage';

const NORWEGIAN_LANGUAGES_ARRAY = ['nb', 'nn'];

const getTVProgram = (hour: number) => {
  const id = toUnixTime(`${hour}:00:00`);

  const programs = document.querySelectorAll<HTMLElement>(
    '#channels-schedule .slick-slide div .MuiBox-root',
  );

  for (let i = 0; i < programs.length; i++) {
    programs[i].style.display = 'block';
    if (
      Number(programs[i].getAttribute('id')) < Number(id) ||
      Number(programs[i].getAttribute('id')) > Number(id) + 3600
    ) {
      programs[i].style.display = 'none';
    }
  }
};

const hideOverflow = () => {
  document.body.style.overflow = 'hidden';
};

const showOverflow = () => {
  document.body.style.overflow = 'auto';
};

const getMediaLinkSource = (mediaLinksData: MediaLiteData) => {
  return mediaLinksData?.sources?.filter((item) => item.type === 'web')[0];
};

const navigateToServiceOrPlayerFn = (
  navigateCallback: NavigateFunction,
  currentLinkObj?: MediaLinkData,
  mediaLinksData?: MediaLiteData,
) => {
  if (currentLinkObj && mediaLinksData) {
    const { channelId, backPath, sourceId, isStreaming, link, viewProgress } = currentLinkObj;
    const configureSearchParams = () => {
      return `?channelId=${channelId}&sourceId=${sourceId}&viewProgress=${viewProgress}&link=${link}&episodeId=${
        mediaLinksData.isSeries ? mediaLinksData.lastPlayedSource.episodeId : undefined
      }&backPath=${backPath}&title=${mediaLinksData.title}`;
    };

    if (isStreaming) {
      navigateCallback(`${appLinks.player.asLink(mediaLinksData.id)}${configureSearchParams()}`);
    } else {
      window.open(currentLinkObj.link, '_self');
    }
  }
};

const getTrimmedText = (d: string, length = 15): string =>
  d.length > length ? `${d.substr(0, length)}...` : d;

const getBrowserLanguage = () => {
  const notSplitableLanguages = ['es-ar', 'pt-br', 'zh-cn', 'zh-tw'];
  const browserLanguage = navigator.language.toLocaleLowerCase();

  const language = notSplitableLanguages.includes(browserLanguage)
    ? browserLanguage
    : browserLanguage.split('-')[0];

  if (NORWEGIAN_LANGUAGES_ARRAY.includes(language)) {
    return 'no';
  }
  return language;
};

const getActiveLanguage = () => {
  const language = getLocationFromLocalStorage()?.replace('-', '');

  if (language) {
    if (NORWEGIAN_LANGUAGES_ARRAY.includes(language)) {
      return 'no';
    }
    return language;
  }
  return getBrowserLanguage();
};

export {
  getTVProgram,
  hideOverflow,
  showOverflow,
  getMediaLinkSource,
  navigateToServiceOrPlayerFn,
  getTrimmedText,
  getBrowserLanguage,
  getActiveLanguage,
};
